import React, { FC, Fragment } from 'react';





interface ProjectsProps { }

const Projects: FC<ProjectsProps> = () => {
	return (
		<Fragment>
		
		</Fragment>
	);
};

export default Projects;
