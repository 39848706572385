import React, { Fragment } from "react";
import { Breadcrumb } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const Pageheader = () => {
  const { pathname } = useLocation();
  const commonPath = "/velvet-ts/preview/";
  const trimmedPathname = pathname.startsWith(commonPath) ? pathname.slice(commonPath.length) : pathname;
  const locationArray = trimmedPathname.split("/").filter(Boolean);

  // interface BreadcrumbItemProps {
  //   item: string;
  //   index: number;
  //   isActive: boolean;
  // }

  const capitalizeFirstLetter = (string: string): string => string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

  return (
    <Fragment>
      <div className="d-sm-flex d-block align-items-center justify-content-between page-header-breadcrumb" style={{ zIndex: 0 }}>
        <h4 className="fw-medium mb-0" style={{fontSize:'24px !important'}}>Welcome to QliniQ</h4>
        <div className="ms-sm-1 ms-0">
          <nav>
            <Breadcrumb className="breadcrumb mb-0">
              {locationArray.map((item, index) => (
                <Breadcrumb.Item
                  key={index}
                  active={index === locationArray.length - 1}
                  href="#"
                >
                  {capitalizeFirstLetter(item)}
                </Breadcrumb.Item>
              ))}
            </Breadcrumb>
          </nav>
        </div>
      </div>
    </Fragment>
  );
};

export default Pageheader;
