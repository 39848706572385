import CryptoJS from "crypto-js";
import { VITE_SECRET_KEY } from "../constants";

const secretKey = VITE_SECRET_KEY;

if (!secretKey) {
  throw new Error("Secret key is not defined.");
}

export const encrypt = (text: string): string => {
  return CryptoJS.AES.encrypt(text, secretKey).toString();
};

export const decrypt = (encryptedString: string): string => {
  const bytes = CryptoJS.AES.decrypt(encryptedString, secretKey);
  return bytes.toString(CryptoJS.enc.Utf8);
};
