import React, { useState } from "react";
import Layout from "../layout";
import {
  StyledContainer,
  StyledHeading,
  StyledText,
  StyledColumn,
  StyledInput,
  StyledLabel,
  StyledButton,
  StyledCheckboxWrapper,
  StyledLogo,
  StyledDiv,
} from "./styles";
import logo from "../../assets/images/layout/clinicLogo.png";
import { post } from "../../services/apiService";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import { StringKey } from "react-table";
// import { useSnackbar } from "../../services/snackbarContext";

const Signin: React.FC = () => {
  const navigate = useNavigate();
  // const { showSnackbar } = useSnackbar();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState(false);
  const handleLogin = async () => {
    try {
      const response = await post<{ status: string, data: { org_name: string, first_name: string, last_name: string, role_name: string}[] }>("/login", {
        email,
        password,
      });
  
      if (response?.status === "00") {
        const org_name = response.data[0].org_name;
        const first_name = response.data[0].first_name
        const last_name = response.data[0].last_name
        const role_name = response.data[0].role_name
        localStorage.setItem("org_name", org_name);
        localStorage.setItem("first_name", first_name); 
        localStorage.setItem("last_name", last_name); 
        localStorage.setItem("role_name", role_name); 
        toast.success("Login successful!");
        // Redirect to dashboard
        navigate("/dashboards/Usersetup/");
      } else {
        toast.error("Invalid credentials. Please try again.");
        // toast.error("Invalid credentials. Please try again.");
      }
    } catch (error) {
      toast.error(
        "An error occurred during login. Please check your network and try again."
       
      );
    }
  };
  

  return (
    <Layout>
      <ToastContainer position="top-right" autoClose={5000} />
      <StyledDiv>
        <StyledLogo src={logo} alt="Clinic Logo" />
      </StyledDiv>
      <StyledContainer>
        <StyledHeading>Sign In</StyledHeading>
        <StyledText>Welcome to the QliniQ</StyledText>
        <StyledColumn>
          <StyledLabel>User Name</StyledLabel>
          <StyledInput
            type="text"
            placeholder="enter username"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </StyledColumn>
        <StyledColumn>
          <StyledLabel>
            Password<span onClick={()=> navigate("/forgot")}>Forgot Password?</span>
          </StyledLabel>
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            <StyledInput
              type={showPassword ? "text" : "password"}
              placeholder="enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={{
                width: "100%",
                paddingRight: "40px", // Space for the eye icon
                boxSizing: "border-box",
              }}
            />
            <div
              onClick={() => setShowPassword(!showPassword)}
              style={{
                position: "absolute",
                right: "10px", // Adjust alignment
                cursor: "pointer",
                color: "#333", // Optional: Adjust icon color
              }}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </div>
          </div>
          <StyledCheckboxWrapper>
            <input type="checkbox" id="termsCheckbox" />
            <label htmlFor="termsCheckbox">Remember Password?</label>
          </StyledCheckboxWrapper>
        </StyledColumn>
        <StyledButton onClick={handleLogin}>Sign In</StyledButton>
        <StyledText isAlternate={true}>
          Dont have an account? <span onClick={() => navigate("/subscribe")}>Subscribe</span>
        </StyledText>
      </StyledContainer>
    </Layout>
  );
};

export default Signin;
