// old code working
import React, { FC, useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { post } from "../../../services/apiService";
import Select from "react-select";
import { toast, ToastContainer } from "react-toastify";
import { fileUpload } from "../../../services/apiService";
import { encrypt } from "../../../services/cryptoService";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import "../style.css";
import "./styles.css";
import { useSearchParams } from 'react-router-dom';

interface SubscribeFormProps {
  activeStep: number;
  setActiveStep: (step: number) => void;
}

interface ApiResponse<T> {
  status: string;
  message: string;
  data?: T;
}

interface Country {
  country_id: string;
  country_name: string;
}

interface Province {
  province_id: string;
  province_name: string;
}

interface City {
  city_id: string;
  city_name: string;
}

interface SetSubscriberData {
  subscriberId?: string;
}



const SubscribeForm: FC<SubscribeFormProps> = ({
  activeStep,
  setActiveStep,
}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [plan, setPlan] = useState(1);
  const [planAmount, setPlanAmount] = useState<number>(49);
  const [countries, setCountries] = useState<Country[]>([]);
  const [provinces, setProvinces] = useState<Province[]>([]);
  const [cities, setCities] = useState<City[]>([]);
  const [initialCountry, setInitialCountry] = useState<string>("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [fileName, setFileName] = useState<string>("");
  const [showPassword, setShowPassword] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  // useEffect(() => {
  //   const fetchCountries = async () => {
  //     try {
  //       const response = await post<ApiResponse<Country[]>>("/countries", {});
  //       setCountries(response.data || []);
  //     } catch (error) {
  //       console.error("Error fetching countries:");
  //     }
  //   };
  //   fetchCountries();
  // }, []);

  useEffect(() => {
    if (activeStep !== 0) return;

    const fetchCountries = async () => {
      try {
        const response = await post<ApiResponse<Country[]>>("/countries", {});
        setCountries(response.data || []);
      } catch (error) {
        console.error("Error fetching countries:");
      }
    };

    fetchCountries();
  }, [activeStep]);

  useEffect(() => {
    const planValue = searchParams.get('plan');
    if (planValue) {
      setPlan(Number(planValue));
      fetchPlanAmount(Number(planValue));
      console.log(`Plan ${planValue} selected`);
    }
  }, [searchParams]);

  const fetchPlanAmount = async (planId: number = 1) => {
    try {
      const response = await post<ApiResponse<any>>("/get-amount", { planId });
      if (response.status === "00" && response.data) {
        setPlanAmount(response.data[0].plan_amount || 0);
      } else {
        console.error("Error fetching plan amount:", response.message);
        toast.error("Error fetching plan amount");
      }
    } catch (error) {
      console.error("Error fetching plan amount:", error);
      toast.error("Failed to fetch plan amount");
    }
  };



  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    try {
      const encryptedPassword = encodeURIComponent(encrypt(values.password));

      const subscriberData = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        password_txt: encryptedPassword,
        org_name: values.org_name,
        org_street_1: values.street_address,
        org_street_2: "",
        org_country_id: values.country,
        org_province_id: values.state,
        org_city_id: values.city,
        town_name: values.town,
        org_postcode: values.zip_code,
      };

      let fileResponse = null;
      try {
        if (!selectedFile) {
          try {
            fileResponse = await fileUpload(
              null,
              values.org_name,
              "default_image.png"
            );
            // toast.success(fileResponse.message);
            console.log("Default image used:", fileResponse);
          } catch (fileError: any) {
            console.error("Error using default image:", fileError.message);
            // toast.error(fileError.message);
          }
        }
      } catch (fileError: any) {
        console.error("File upload failed:", fileError.message);
      }

      // Call the backend endpoint that triggers `sp_SetSubscriber`
      const response = await post<ApiResponse<SetSubscriberData>>(
        "/set-subscriber",
        subscriberData
      );
      console.log("response", response);

      if (response.status === "02") {
        toast.error(response.message);
      } else if (response.status === "00") {
        toast.success("Form Data submitted successfully");
        localStorage.setItem("userEmail", values.email);
        const subscriberId = response?.data?.subscriberId;
        if (subscriberId) {
          localStorage.setItem("subscriberid", subscriberId);

          // Send OTP after successfully saving subscriber data
          // const otpData: SendOtpData = {
          //   subscriberId: subscriberId,
          // };
          // const otpResponse = await post<any>("/send-otp", {
          //   subscriberId: otpData.subscriberId,
          // });
          // toast(otpResponse.message);
          setActiveStep(activeStep + 1); // Proceed to the next step
        } else {
          toast.error("Failed to get subscriber ID");
          console.error("Failed to get subscriber ID");
        }
      } else {
        toast.error(response.message);
      }
    } catch (error: any) {
      toast.error(error.message);
    } finally {
      setSubmitting(false);
    }
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: any) => void,
    values: any
  ) => {
    const file = event.target.files?.[0];

    if (!file) {
      return;
    }

    console.log(file);

    // File type validation (only images)
    const validTypes = ["image/jpeg", "image/png", "image/gif", "image/jpg"];
    if (!validTypes.includes(file.type)) {
      toast.error("Please upload a valid image file (JPEG, PNG, GIF, JPG).");
      event.target.value = ""; // Clear the file input
      setFieldValue("file_upload", "");
      return;
    }

    // File size validation (e.g., max 5MB)
    const maxSize = 5 * 1024 * 1024; // 5MB
    if (file.size > maxSize) {
      toast.error("File size should not exceed 5MB.");
      event.target.value = ""; // Clear the file input
      setFieldValue("file_upload", "");
      return;
    }

    // If valid, update the file name and handle the file
    setFileName(file.name);
    setSelectedFile(file);
    setFieldValue("file_upload", event.target.value);

    try {
      let fileResponse = null;
      if (file) {
        fileResponse = await fileUpload(
          file,
          values.org_name, // Use the current form value for org_name
          file.name
        );
        toast.success(fileResponse.message);
        console.log("Upload success:", fileResponse);
      }
    } catch (fileError: any) {
      console.error("File upload failed:", fileError.message);
      toast.error(fileError.message);
      // Clear the file input on error
      event.target.value = "";
      setFieldValue("file_upload", "");
      setSelectedFile(null);
      setFileName("");
    }
  };

  useEffect(() => {
    const fetchCountryFromIP = async (): Promise<void> => {
      if (countries.length === 0) return;
      try {
        const response = await fetch("http://ip-api.com/json");
        const data = await response.json();

        if (data && data.country) {
          // console.log("IP Country:", data.country);
          // console.log("hellooo");
          const country = countries.find(
            (country) => country.country_name === data.country
          );

          if (country) {
            setInitialCountry(country.country_id);
          }
        }
      } catch (error) {
        console.error("Error fetching country from ipstack:", error);
      }
    };
    fetchCountryFromIP();
  }, [countries]);

  const initialValues = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    org_name: "",
    country: initialCountry,
    state: "",
    city: "",
    street_address: "",
    town: "",
    zip_code: "",
    file_upload: "",
  };

  // Validation schema using Yup
  const validationSchema = Yup.object({
    first_name: Yup.string()
      .min(2, "Too Short!")
      .max(30, "Too Long!")
      .required("First name is required"),
    last_name: Yup.string()
      .min(2, "Too Short!")
      .max(30, "Too Long!")
      .required("Last name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/[\W_]/, "Password must contain at least one special character")
      .required("Password is required"),
    country: Yup.string().required("Country is required"),
    street_address: Yup.string()
      .min(5, "Too Short!")
      .max(300, "Too Long!")
      .required("Street Address is required"),
    org_name: Yup.string()
      .max(300, "Too Long!")
      .required("Organization name is required"),
    state: Yup.string().required("State/province is required"),
    city: Yup.string(),
    file_upload: Yup.mixed(),
  });

  return (
    <div className="page error-bg" id="particles-js">
      <ToastContainer position="top-right" autoClose={5000} />
      <div className="error-page">
        <div className="container-lg">
          <div className="row justify-content-center align-items-center authentication authentication-basic h-100 ">
            <div className="col-12 ">
              <div className="card custom-card ">
                <div
                  className="d-flex flex-column flex-md-row justify-content-md-between align-items-md-center py-md-3 py-4 px-xl-5  px-sm-4"
                  style={{
                    background: "linear-gradient(to right, #034737,#ff9800 )",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                  }}
                >
                  <div>
                    <h4 className="mb-md-1 mb-sm-0 text-light item1">
                      Subscribe
                    </h4>
                    <p className="text-light op-9 fs-md-14  mb-0 fw-normal item1">
                      Welcome & Join us to transform lives with{" "}
                      <span className="fw-bold">QliniQ</span>
                    </p>
                  </div>
                  <h4 className="mb-0 mt-3 mt-md-0 text-light item1">
                    Plan: ${planAmount}/<span>month</span>
                  </h4>
                </div>
                <div className="card-body p-sm-5">
                  <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({
                      isSubmitting,
                      setFieldTouched,
                      setFieldValue,
                      errors,
                      touched,
                      values,
                    }) => {
                      useEffect(() => {
                        if (values.country) {
                          const fetchProvinces = async () => {
                            try {
                              const response = await post<
                                ApiResponse<Province[]>
                              >("/provinces", {
                                countryId: values.country,
                              });
                              setProvinces(response.data || []);
                            } catch (error) {
                              console.error("Error fetching provinces:", error);
                            }
                          };
                          fetchProvinces();
                        } else {
                          setProvinces([]);
                          setCities([]);
                        }
                      }, [values.country]);

                      useEffect(() => {
                        if (values.state) {
                          const fetchCities = async () => {
                            try {
                              const response = await post<ApiResponse<City[]>>(
                                "/cities",
                                {
                                  provinceId: values.state,
                                }
                              );
                              setCities(response.data || []);
                            } catch (error) {
                              console.error("Error fetching cities:", error);
                            }
                          };
                          fetchCities();
                        } else {
                          setCities([]);
                        }
                      }, [values.state]);
                      return (
                        <Form>
                          <div className="row gy-3">
                            <h5 className="fw-semibold">Personal details:</h5>
                            <Col xl={6}>
                              <label
                                htmlFor="first_name"
                                className="form-label text-default"
                              >
                                First Name
                              </label>
                              <Field
                                type="text"
                                name="first_name"
                                className={`form-control form-control-lg ${
                                  touched.first_name && errors.first_name
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder="First name"
                              />
                              <ErrorMessage
                                name="first_name"
                                component="div"
                                className="text-danger"
                              />
                            </Col>
                            <Col xl={6}>
                              <label
                                htmlFor="last_name"
                                className="form-label text-default"
                              >
                                Last Name
                              </label>
                              <Field
                                type="text"
                                name="last_name"
                                className={`form-control form-control-lg ${
                                  touched.last_name && errors.last_name
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder="Last name"
                              />
                              <ErrorMessage
                                name="last_name"
                                component="div"
                                className="text-danger"
                              />
                            </Col>
                            <Col xl={6}>
                              <label
                                htmlFor="email"
                                className="form-label text-default"
                              >
                                Email
                              </label>
                              <Field
                                type="email"
                                name="email"
                                className={`form-control form-control-lg ${
                                  touched.email && errors.email
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder="Organization email"
                              />
                              <ErrorMessage
                                name="email"
                                component="div"
                                className="text-danger"
                              />
                            </Col>
                            <Col xl={6} className="position-relative">
                              <label
                                htmlFor="password"
                                className="form-label text-default"
                              >
                                Password
                              </label>
                              <Field
                                type={showPassword ? "text" : "password"}
                                name="password"
                                className={`form-control form-control-lg ${
                                  touched.password && errors.password
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder="Password"
                              />
                              {!errors.password && (
                                <button
                                  type="button"
                                  onClick={() => setShowPassword(!showPassword)}
                                  className="position-absolute end-0 top-50 border-0 bg-transparent pe-4"
                                  style={{
                                    cursor: "pointer",
                                    color: "#FF9800",
                                    fontSize: "16px",
                                  }}
                                >
                                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                                </button>
                              )}

                              <ErrorMessage
                                name="password"
                                component="div"
                                className="text-danger"
                              />
                            </Col>
                            <h5 className="fw-semibold mt-5 ">
                              Organization details:
                            </h5>
                            <Col xl={4}>
                              <label
                                htmlFor="org_name"
                                className="form-label text-default"
                              >
                                Organization Name
                              </label>
                              <Field
                                type="text"
                                name="org_name"
                                className={`form-control form-control-lg ${
                                  touched.org_name && errors.org_name
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder="Organization Name"
                              />
                              <ErrorMessage
                                name="org_name"
                                component="div"
                                className="text-danger"
                              />
                            </Col>
                            <Col xl={8}>
                              <label
                                htmlFor="file_upload"
                                className="form-label text-default"
                              >
                                Organization Logo
                              </label>
                              <Field
                                type="file"
                                name="file_upload"
                                accept="image/jpeg,image/png,image/gif,image/jpg"
                                disabled={!values.org_name}
                                style={{ color: "lightgray" }}
                                className={`form-control form-control-lg ${
                                  touched.file_upload && errors.file_upload
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder="Organization Logo"
                                onChange={(event: any) => {
                                  handleFileChange(
                                    event,
                                    setFieldValue,
                                    values
                                  );
                                }}
                              />
                              {fileName && (
                                <small className="text-muted">
                                  Selected file: {fileName}
                                </small>
                              )}
                              <ErrorMessage
                                name="file_upload"
                                component="div"
                                className="text-danger"
                              />
                            </Col>
                            {/* <Col xl={8}>
                              <label
                                htmlFor="file_upload"
                                className="form-label text-default"
                              >
                                Organization Logo
                              </label>
                              <Field name="org_name">
                                {({
                                  field,
                                  form,
                                }: {
                                  field: any;
                                  form: any;
                                }) => (
                                  <>
                                    <input
                                      type="file"
                                      name="file_upload"
                                      accept="image/jpeg,image/png,image/gif,image/jpg"
                                      style={{ color: "lightgray" }}
                                      className={`form-control form-control-lg ${
                                        touched.file_upload &&
                                        errors.file_upload
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      placeholder="Organization Logo"
                                      disabled={!form.values.org_name}
                                      onChange={(event: any) => {
                                        if (form.values.org_name) {
                                          handleFileChange(
                                            event,
                                            setFieldValue,
                                            values
                                          );
                                        }
                                      }}
                                    />
                                  </>
                                )}
                              </Field>
                              {fileName && (
                                <small className="text-muted">
                                  Selected file: {fileName}
                                </small>
                              )}
                              <ErrorMessage
                                name="file_upload"
                                component="div"
                                className="text-danger"
                              />
                            </Col> */}
                            <Col xl={4}>
                              <label
                                htmlFor="country"
                                className="form-label text-default"
                              >
                                Country
                              </label>
                              <Select
                              // className="custom-select-styles"
                                isSearchable={true}
                                name="country"
                                value={
                                  countries
                                    .filter(
                                      (country) =>
                                        country.country_id === values.country
                                    )
                                    .map((country) => ({
                                      value: country.country_id,
                                      label: country.country_name,
                                    }))[0]
                                }
                                options={countries.map((country) => ({
                                  value: country.country_id,
                                  label: country.country_name,
                                }))}
                                className={`custom-select-styles default basic-multi-select ${
                                  touched.country && errors.country ? "is-invalid" : ""
                                }`}
                                id="country"
                                menuPlacement="auto"
                                classNamePrefix="Select2"
                                //	onBlur={() => setFieldTouched('country', true)} // Manually trigger the touched state
                                onChange={(selectedOption) => {
                                  setFieldValue(
                                    "country",
                                    selectedOption?.value || ""
                                  );
                                  setFieldValue("state", ""); // Clear dependent fields
                                  setFieldValue("city", "");
                                }}
                                styles={{
                                  control: (base, state) => ({
                                    
                                    ...base,
                                    borderColor:
                                      touched.country && errors.country
                                        ? "red"
                                        : base.borderColor, // Set red border on error
                                        
                                       
                                  }),
                                  
                                }}
                              />
                              <ErrorMessage
                                name="country"
                                component="div"
                                className="text-danger"
                              />
                            </Col>
                            <Col xl={4}>
                              <label
                                htmlFor="state"
                                className="form-label text-default"
                              >
                                State
                              </label>
                              <Select
                                isSearchable={true}
                                name="state"
                                options={provinces.map((province) => ({
                                  value: province.province_id,
                                  label: province.province_name,
                                }))}
                                value={
                                  provinces
                                    .filter(
                                      (province) =>
                                        province.province_id === values.state
                                    )
                                    .map((province) => ({
                                      value: province.province_id,
                                      label: province.province_name,
                                    }))[0]
                                }
                                className={`custom-select-styles default basic-multi-select ${
                                  touched.state && errors.state
                                    ? "is-invalid"
                                    : ""
                                }`}
                                id="state"
                                menuPlacement="auto"
                                classNamePrefix="Select2"
                                onChange={(selectedOption) => {
                                  setFieldValue(
                                    "state",
                                    selectedOption?.value || ""
                                  );
                                  setFieldValue("city", "");
                                }}
                                styles={{
                                  control: (base, state) => ({
                                    ...base,
                                    borderColor:
                                      touched.state && errors.state
                                        ? "red"
                                        : base.borderColor,
                                  }),
                                }}
                              />
                              <ErrorMessage
                                name="state"
                                component="div"
                                className="text-danger"
                              />
                            </Col>
                            <Col xl={4}>
                              <label
                                htmlFor="city"
                                className="form-label text-default"
                              >
                                City
                              </label>
                              <Select
                                isSearchable={true}
                                name="city"
                                options={cities.map((city) => ({
                                  value: city.city_id,
                                  label: city.city_name,
                                }))}
                                value={
                                  cities
                                    .filter(
                                      (city) => city.city_id === values.city
                                    )
                                    .map((city) => ({
                                      value: city.city_id,
                                      label: city.city_name,
                                    }))[0]
                                }
                                className={`custom-select-styles default basic-multi-select ${
                                  touched.city && errors.city
                                    ? "is-invalid"
                                    : ""
                                }`}
                                id="city"
                                menuPlacement="auto"
                                classNamePrefix="Select2"
                                onBlur={() => setFieldTouched("city", true)}
                                onChange={(selectedOption) => {
                                  setFieldValue(
                                    "city",
                                    selectedOption?.value || ""
                                  );
                                }}
                                styles={{
                                  control: (base, state) => ({
                                    ...base,
                                    borderColor:
                                      touched.city && errors.city
                                        ? "red"
                                        : base.borderColor,
                                  }),
                                }}
                              />
                              <ErrorMessage
                                name="city"
                                component="div"
                                className="text-danger"
                              />
                            </Col>
                            <Col xl={4}>
                              <label
                                htmlFor="street_address"
                                className="form-label text-default"
                              >
                                Street Address
                              </label>
                              <Field
                                type="text"
                                name="street_address"
                                className={`form-control form-control-lg ${
                                  touched.street_address &&
                                  errors.street_address
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder="Street Address"
                              />
                              <ErrorMessage
                                name="street_address"
                                component="div"
                                className="text-danger"
                              />
                            </Col>
                            <Col xl={4}>
                              <label
                                htmlFor="street_address"
                                className="form-label text-default"
                              >
                                Town
                              </label>
                              <Field
                                type="text"
                                name="town"
                                className={`form-control form-control-lg ${
                                  touched.street_address &&
                                  errors.street_address
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder="Town"
                              />
                              <ErrorMessage
                                name="street_address"
                                component="div"
                                className="text-danger"
                              />
                            </Col>
                            <Col xl={4}>
                              <label
                                htmlFor="zip_code"
                                className="form-label text-default"
                              >
                                Zip
                              </label>
                              <Field
                                type="text"
                                name="zip_code"
                                className={`form-control form-control-lg ${
                                  touched.zip_code && errors.zip_code
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder="Zip Code"
                              />
                              <ErrorMessage
                                name="zip_code"
                                component="div"
                                className="text-danger"
                              />
                            </Col>

                            <div className="d-flex flex-column justify-content-center d-grid mt-5">
                              <div className="d-flex flex-row">
                                <input
                                  type="checkbox"
                                  id="termsCheckbox"
                                  checked={termsAccepted}
                                  onChange={(e) =>
                                    setTermsAccepted(e.target.checked)
                                  }
                                />
                                <label htmlFor="termsCheckbox" className="ps-1">
                                  By clicking this, I agree to QlinQ{" "}
                                  <a href="#">Terms & Conditions</a> and{" "}
                                  <a href="#">Privacy Policy</a>.
                                </label>
                              </div>

                              <button
                                className="btn btn-lg btn-green mt-2 w-100"
                                disabled={isSubmitting || !termsAccepted}
                              >
                                {isSubmitting ? "Submitting..." : "Subscribe"}
                              </button>
                            </div>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                  <div className="text-center">
                    <p className="fs-12 text-muted mt-2">
                      Already have an account?{" "}
                      <span
                        style={{ color: "#FF9800", cursor: "pointer" }}
                        onClick={() => navigate("/signin")}
                      >
                        Sign in
                      </span>
                      {/* <Link
                        to={`${
                          import.meta.env.BASE_URL
                        }authentication/signin/signinbasic`}
                        style={{ color: "#FF9800 !important" }}
                      >
                        Sign In
                      </Link> */}
                    </p>
                  </div>
                  {/* <div className="text-center my-3 authentication-barrier">
                    <span>OR</span>
                  </div>
                  <div className="btn-list text-center">
                    <button
                      aria-label="button"
                      type="button"
                      className="btn btn-icon btn-light btn-wave waves-effect waves-light"
                    >
                      <i className="ri-google-line fw-bold "></i>
                    </button>
                    <button
                      aria-label="button"
                      type="button"
                      className="btn btn-icon btn-light btn-wave waves-effect waves-light"
                    >
                      <i className="ri-linkedin-line fw-bold "></i>
                    </button>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <ToastContainer /> */}
    </div>
  );
};

export default SubscribeForm;
