import React from "react";
import logo from "../../../assets/images/layout/clinicLogo.png";

interface HeaderProps {
  showCancelButton?: boolean;
}

const HeaderQliniq: React.FC<HeaderProps> = ({ showCancelButton = false }) => {
  return (
    <header className="bg-light py-3 border-bottom">
      <div className="container d-flex justify-content-between align-items-center">
        <img src={logo} alt="Logo" height="40" />

        {showCancelButton && (
          <button className="btn btn-green">Cancel Subscription</button>
        )}
      </div>
    </header>
  );
};

export default HeaderQliniq;
