import styled from "styled-components";

// Define interfaces for props
interface StyledTextProps {
  isRed?: boolean;
  isResendText?: boolean;
}

interface StyledButtonProps {
  isAlternate?: boolean;
}

export const StyledContainer = styled.div`
  width: 56%;
  margin: 4% auto 0 auto;
  border: 1px solid #ededed;
  border-radius: 10px;
  box-shadow: 5px 5px 25px 5px #0000000d;
  background: #fff;
  padding: 2rem 3.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  justify-content: center;
  @media (max-width: 800px) {
    width:90%;
  }
`;

export const StyledHeading = styled.div`
  color: #034737;
  font-size: 24px;
  font-weight: bold;
  line-height: 28.2px;
  @media (max-width: 1080px) {
    text-align: center;
  }
`;

export const StyledText = styled.p<StyledTextProps>`
  color: ${(props) => (props.isRed ? "#FF0000" : "#909090")};
  font-size: 14px;
  text-align: center;
  align-self: ${(props) => (props.isResendText ? "flex-start" : "center")};
  font-weight: ${(props) => (props.isResendText ? "500" : "400")};
  margin-top: ${(props) => (props.isRed ? "-3%" : "0")};

  span {
    text-decoration: underline;
    cursor: pointer;
    color: #ff9800;
  }
`;

export const StyledButton = styled.button<StyledButtonProps>`
  background-color: #013e30;
  color: white;
  border: none;
  border-radius: 4px;
  padding: ${(props) => (props.isAlternate ? "5px 20px" : "10px 20px")};
  font-size: ${(props) => (props.isAlternate ? "0.8rem" : "1rem")};
  cursor: pointer;
  width: 100%;

  &:hover {
    background-color: #025b46;
  }
`;
