import React, { useState, useEffect, ComponentType } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import { useNavigate, useSearchParams } from "react-router-dom";
import "../styles.css";


interface DynamicStepperProps {
  steps: string[];
  components: ComponentType<{
    activeStep: number;
    setActiveStep: (step: number) => void;
  }>[]; 
  initialStep?: number; 
}

const DynamicStepper: React.FC<DynamicStepperProps> = ({
  steps,
  components,
  initialStep = 0,
}) => {
  const [activeStep, setActiveStep] = useState<number>(initialStep);

  const navigate = useNavigate(); 
  const [searchParams, setSearchParams] = useSearchParams(); 


  useEffect(() => {
    const stepParam = searchParams.get("step");
    if (stepParam) {
      const parsedStep = parseInt(stepParam, 10);
      if (!isNaN(parsedStep) && parsedStep >= 0 && parsedStep < steps.length) {
        setActiveStep(parsedStep); 
      }
    }
  }, []);

 
  useEffect(() => {
    setSearchParams({ step: activeStep.toString() }); 
  }, [activeStep, setSearchParams]);

  const CurrentStepComponent = components[activeStep];

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep} className="stepper-container1">
        {steps.map((label, index) => (
          <Step
            style={{ whiteSpace: "nowrap" }}
            key={label}
            sx={{
              "& .MuiStepLabel-root .Mui-completed": {
                color: "#4CAF50", // circle color (COMPLETED)
              },
              "& .MuiStepLabel-label.Mui-completed": {
                color: "#013E30", // Just text label (COMPLETED)
              },
              "& .MuiStepLabel-root .Mui-active": {
                color: "#013E30", // circle color (ACTIVE)
              },
              "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                {
                  color: "#013E30", // Just text label (ACTIVE)
                },
              "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                fill: "#fff", // circle's number (ACTIVE)
              },
            }}
          >
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            All steps completed - you&apos;re finished
          </Typography>
        </React.Fragment>
      ) : (
        <Box sx={{ mt: 2, mb: 1 }}>
          {CurrentStepComponent && (
            <CurrentStepComponent
              activeStep={activeStep}
              setActiveStep={setActiveStep}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default DynamicStepper;


