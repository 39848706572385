import React, { ReactNode } from "react";
import avatar from "../assets/images/layout/clinic_avatar.png";
import HeaderQliniq from "../components/common/headerqliniq/headerqliniq";
import "./styles.css";
interface LayoutProps {
  children: ReactNode;
  showCancelButton?: boolean;


}

const Layout: React.FC<LayoutProps> = ({ children, showCancelButton = false}) => {
  return (
    <div
      className="d-flex flex-column position-relative bg-light"
      style={{ zIndex: 100, minHeight: "100vh" }}
    >
      <HeaderQliniq showCancelButton={showCancelButton} />

      <div
        className="position-absolute w-100"
        style={{
          top: "0",
          bottom: "0",
          height: "350px",
          backgroundColor: "#E1E1E1",
          zIndex: "-1",
        }}
      ></div>

      <div className="container mt-5 w-75 d-flex flex-column align-items-center justify-content-center newClassTsx">
        {children}
      </div>

      {/* Avatar */}
      <div
        className="position-absolute"
        style={{
          bottom: "0px",
          right: "4%",
        }}
      >
        <img
          src={avatar}
          alt="Avatar"
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      </div>
    </div>
  );
};

export default Layout;
