import React, { FC, Fragment } from "react";

interface JobsProps {}

const Jobs: FC<JobsProps> = () => {
  return (
    <Fragment>
      
    </Fragment>
  );
};

export default Jobs;
