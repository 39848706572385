import React, { useEffect, useState } from "react";
import {
  StyledButton,
  StyledContainer,
  StyledHeading,
  StyledText,
} from "./styles";
import OTPInput from "../../components/common/OtpInput/OtpInput";
import { post } from "../../services/apiService";
// import { useSnackbar } from "../../services/snackbarContext";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";

const Otp: React.FC = () => {
  const navigate = useNavigate();
  // const { showSnackbar } = useSnackbar();
  const [otp, setOtp] = useState<string>(""); // OTP value
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false); // Button state
  const [refreshTime, setRefreshTime] = useState<number | null>(null); // Refresh interval
  const [counter, setCounter] = useState<number>(120); // Countdown timer

  // Starts a countdown timer
  const startCountdown = (initialTime: number) => {
    const intervalId = setInterval(() => {
      setCounter((prevCounter) => {
        if (prevCounter <= 1) {
          clearInterval(intervalId); // Stop when counter reaches 0
          return 0;
        }
        return prevCounter - 1;
      });
    }, 1000);
  };

  // Fetch initial OTP configuration
  useEffect(() => {
    getConfigValue();
  }, []);

  // Restart countdown when refreshTime changes
  useEffect(() => {
    if (refreshTime !== null) {
      const initialCounter = refreshTime * 60;
      setCounter(initialCounter);
      startCountdown(initialCounter);
    }
  }, [refreshTime]);

  // Update OTP value
  const handleComplete = (enteredOtp: string) => {
    setOtp(enteredOtp);
  };

  // Verify the entered OTP
  const handleVerify = async () => {
    const subscriberId = localStorage.getItem("subscriberid");

    if (!subscriberId) {
      toast.error("Please try again later");
      return;
    }

    const numericOtp = Number(otp);
    try {
      setIsSubmitting(true);
      const response = await post<{ status: string; message?: string }>("/verify-otp", {
        subscriberId: JSON.parse(subscriberId),
        verificationCode: numericOtp,
      });

      if (response.status === "00") {
        toast.success("Otp verified successfully!");
        navigate("/signin");
      } else {
        toast.error(response.message || "OTP verification failed");
      }
    } catch (error: any) {
      toast.error(error.message || "An error occurred during OTP verification.");
    } finally {
      setIsSubmitting(false);
    }
  };

  // Resend the OTP and restart the countdown
  const handleResend = async () => {
    try {
      const initialCounter = refreshTime ? refreshTime * 60 : 120;
      setCounter(initialCounter);
      startCountdown(initialCounter);

      const subscriberId = localStorage.getItem("subscriberid");
      if (!subscriberId) {
        toast.error("Please try again later");
        return;
      }

      await post("/send-otp", { subscriberId: JSON.parse(subscriberId) });
      toast.success("OTP resent successfully!");
    } catch (error: any) {
      toast.error(error.message || "An error occurred while resending OTP.");
    }
  };

  // Fetch OTP window refresh time
  const getConfigValue = async () => {
    try {
      const response = await post<{ status: string; data: { configValue: number } }>(
        "/config-value",
        { attributeName: "OTPwindowRefresh" }
      );
      if (response.status === "00") {
        setRefreshTime(response.data.configValue);
      }
    } catch (error) {
      console.error("Error getting config value: ", error);
    }
  };

  return (
    <StyledContainer>
       <ToastContainer position="top-right" autoClose={5000} />
      <StyledHeading>Verify Your Account</StyledHeading>
      <StyledText>
        Enter the 4 digit code sent to the registered email Id.
      </StyledText>
      <OTPInput length={4} onComplete={handleComplete} />
      <StyledText isResendText={true}>
        Resend OTP after {Math.floor(counter / 60)}:
        {counter % 60 < 10 ? "0" : ""}
        {counter % 60}{" "}
        {counter === 0 && <span onClick={handleResend}>Resend</span>}
      </StyledText>
      <StyledButton onClick={handleVerify} disabled={isSubmitting}>
        {isSubmitting ? "Verifying..." : "Verify"}
      </StyledButton>
    </StyledContainer>
  );
};

export default Otp;
