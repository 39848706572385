import React, { FC, Fragment } from "react";
import {
  Row,
} from "react-bootstrap";



interface CryptoProps {}

const Crypto: FC<CryptoProps> = () => {
  return (
    <Fragment>
      <Row></Row>
    </Fragment>
  );
};

export default Crypto;
