  import React, { useState, useEffect } from "react";
  import {
    StyledContainer,
    StyledHeading,
    StyledLine,
    StyledLeftDiv,
    StyledPaymentOption,
    StyledRadioButton,
    StyledLabel,
    StyledInput,
    StyledButton,
    StyledDiv,
    StyledIcon,
  } from "./styles";
  import paypal from "../../assets/images/payment/paypal.png";
  import googlepay from "../../assets/images/payment/googlepay.png";
  import { PaymentStatus } from "../../types/payment.types";
  import { PayPalButton } from "../../components/client/PayPalButton";
  import { toast, ToastContainer } from "react-toastify";
  import { post } from "../../services/apiService";
  

  interface PaymentProps {
    activeStep: number;
    setActiveStep: (step: number) => void;
  }

  const Payment: React.FC<PaymentProps> = ({
    activeStep,
    setActiveStep,
  }) => {
    const [selectedPayment, setSelectedPayment] = useState<string>("");
    const [isTransactionComplete, setIsTransactionComplete] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState<PaymentStatus | null>(
      null
    );

    // const handlePaymentSuccess = (details: any) => {
    //   setPaymentStatus({
    //     status: "success",
    //     message: `Payment completed! Transaction ID: ${details.id}`,
    //   });
    //   setIsTransactionComplete(true);
    //   toast.success("Transaction successful! Click proceed to continue.");
    // };

    const handlePaymentSuccess = async (details: any) => {
      const { id: transactionId, purchase_units, payer} = details;

      const amount = purchase_units?.[0]?.payments?.captures?.[0]?.amount?.value || "N/A";
      const date = purchase_units?.[0]?.payments?.captures?.[0]?.create_time || "N/A";
      const emailAddress = payer?.email_address || localStorage.getItem("userEmail");
      const name =
    `${payer?.name?.given_name || ""} ${payer?.name?.surname || ""}`.trim();
  const address = [
    purchase_units?.[0]?.shipping?.address?.address_line_1,
    purchase_units?.[0]?.shipping?.address?.admin_area_1,
    purchase_units?.[0]?.shipping?.address?.admin_area_2,
  ]
    .filter(Boolean)
    .join(", ");
      const postalCode = purchase_units?.[0]?.shipping?.address?.postal_code;
      console.log("amount", amount)
    
      setPaymentStatus({
        status: "success",
        message: `Payment completed! Transaction ID: ${transactionId}`,
      });
      setIsTransactionComplete(true);
      toast.success("Transaction successful! Click proceed to continue.");
    
      try {
        // const email = localStorage.getItem("userEmail"); 
        // if (!email) {
        //   toast.error("Email not found for the transaction");
        //   return;
        // }
    
        // Call the API to send an email
        const response = await post<any>("/send-transaction-email", {
          transactionId,
          amount,
          date,
          email: emailAddress,
          name,
          address,
          postalCode,
        });
    
        if (response.status === "00") {
          toast.success(response.message);
        } else {
          toast.error(`Failed to send transaction email: ${response.message}`);
        }
      } catch (error) {
        toast.error("Error sending transaction email. Please try again.");
        console.error("Send email error:", error);
      }
    };
    

    const handlePaymentError = (error: Error) => {
      setPaymentStatus({
        status: "error",
        message: `Payment failed: ${error.message}`,
      });
      setIsTransactionComplete(false);
    toast.error("Payment failed. Please try again.");
    };

    const handleProceedToPayment = async () => {
      console.log("clicked")
      if (!selectedPayment) {
        setPaymentStatus({
          status: "error",
          message: "Please select a payment method",
        });
        toast.warning("Please select a payment method");
        return;
      }
  
      if (!isTransactionComplete) {
        toast.warning("Please complete the payment before proceeding");
        return;
      }
  
      try {
        // Get subscriberId from localStorage
        const subscriberId = localStorage.getItem("subscriberid");
        
        if (!subscriberId) {
          toast.error("Subscriber information not found");
          return;
        }
  
        // Send OTP
        const otpResponse = await post<any>("/send-otp", {
          subscriberId: subscriberId,
        });
        if(otpResponse.status === "00") {
          toast.success(otpResponse.message);
          setActiveStep(activeStep + 1);
        }
        else{
          toast.error(otpResponse.message);
        }
  
        
        // Additional success handling (e.g., navigation) can be added here
  
      } catch (error) {
        toast.error("Failed to send OTP. Please try again.");
        console.error("OTP send error:", error);
      }
    };

    return (
      <StyledContainer>
         <ToastContainer position="top-right" autoClose={5000} />
        <StyledLeftDiv>
          <StyledHeading>Select Payment Option</StyledHeading>
          <StyledLine>All transactions are secure and encrypted</StyledLine>

          {/* Payment Options */}
          <StyledPaymentOption>
            <StyledDiv>
              <StyledRadioButton>
                <StyledInput
                  type="radio"
                  name="payment"
                  id="paypal"
                  checked={selectedPayment === "paypal"}
                  onChange={() => {
                    setSelectedPayment("paypal");
                    setIsTransactionComplete(false); 
                  }}
                />
                <StyledLabel htmlFor="paypal">Paypal / Credit Card</StyledLabel>
              </StyledRadioButton>
              <StyledIcon src={paypal} alt="Paypal" />
            </StyledDiv>
            {selectedPayment === "paypal" && (
              <PayPalButton
                amount="100.00"
                onPaymentSuccess={handlePaymentSuccess}
                onPaymentError={handlePaymentError}
              />
            )}
           

            <StyledDiv>
              <StyledRadioButton>
                <StyledInput type="radio" name="payment" id="googlepay"  checked={selectedPayment === "googlepay"}
                onChange={() => {
                  setSelectedPayment("googlepay");
                  setIsTransactionComplete(false);
                }} />
                <StyledLabel htmlFor="googlepay">Google Pay</StyledLabel>
              </StyledRadioButton>
              <StyledIcon src={googlepay} alt="Google Pay"  />
            </StyledDiv>
          </StyledPaymentOption>
          {/* {paymentStatus && (
            <div
              className={`mt-4 p-4 rounded ${
                paymentStatus.status === "success"
                  ? "bg-green-100 text-green-800"
                  : "bg-red-100 text-red-800"
              }`}
            >
              {paymentStatus.message}
            </div>
          )} */}

          {/* Proceed Button */}
          <StyledButton
            onClick={handleProceedToPayment}
            // disabled={!selectedPayment || !isTransactionComplete}
          >
            Proceed
          </StyledButton>
          
        </StyledLeftDiv>

        
      </StyledContainer>
    );
  };

  export default Payment;
