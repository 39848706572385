import { useEffect, useState } from 'react';

interface UsePayPalSDKProps {
  clientId: string;
  currency?: string;
}

export const usePayPalSDK = ({ clientId, currency = 'USD' }: UsePayPalSDKProps) => {
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const loadScript = () => {
      try {
        const script = document.createElement('script');
        script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}&currency=${currency}`;
        script.async = true;
        
        script.onload = () => setIsScriptLoaded(true);
        script.onerror = () => setError(new Error('Failed to load PayPal SDK'));
        
        document.body.appendChild(script);

        return () => {
          document.body.removeChild(script);
          setIsScriptLoaded(false);
        };
      } catch (err) {
        setError(err instanceof Error ? err : new Error('Failed to load PayPal SDK'));
      }
    };

    const cleanup = loadScript();
    return cleanup;
  }, [clientId, currency]);

  return { isScriptLoaded, error };
};