



const initialState = {
	lang: "en",
	dir: "ltr",
	dataThemeMode: "light",
	dataMenuStyles: "dark",
	dataNavLayout: "vertical",
	dataHeaderStyles: "gradient",
	defaultHeaderStyles: "",
	dataVerticalStyle: "overlay",
	StylebodyBg: "107 64 64",
	StyleDarkBg: "93 50 50",
	toggled: "",
	dataNavStyle: "",
	horStyle: "",
	dataPageStyle: "regular",
	dataWidth: "fullwidth",
	dataMenuPosition: "fixed",
	dataHeaderPosition: "fixed",
	loader: "disable",
	iconOverlay: "",
	colorPrimaryRgb: "",
	bodyBg: "",
	Light: "",
	darkBg: "",
	inputBorder: "",
	bgImg: "",
	iconText: "",
	body: {
		class: ""
	},
	ecommercedata: [
		{
			id: "1",
			preview: '',
			title: "Ugaoo self water plants",
			description: "Ugaoo mini plant with self watring",
			oldpr: "$1,799",
			newpr: "$1,229",
			offerprice: "72% off",
			quantity: "12,456",
			color: "danger",
			images: [
				{ "img":''},
				{ "img":''},
				{ "img":''}],
			// size :'Large'
		},
		{
			id: "2",
			preview: '',
			title: "Light color lilly flowers",
			description: "Light color lilly flowers with pots",
			oldpr: "$2,799",
			newpr: "$6,900",
			offerprice: "Offer Price $599",
			quantity: "(13,456)",
			color: "success",
			images: [
				{ "img": '' },
				{ "img": '' },
				{ "img": '' }],
		},
		{
			id: "3",
			preview: '',
			title: "Ugoa big leaf",
			description: "Ugoa green color big leaf with pot",
			oldpr: "$4,799",
			newpr: "$4,900",
			offerprice: "Offer Price $599",
			quantity: "3,456",
			color: "success",
			images: [
				{ "img": ''},
				{ "img": ''},
				{ "img": ''}],
		},
		{
			id: "4",
			preview: '',
			title: "Money plant",
			description: "Money plant with haning pot",
			oldpr: "$2,799",
			newpr: "$6,900",
			offerprice: "Offer Price $599",
			quantity: "10,456",
			color: "success",
			images: [
				{ "img": ''},
				{ "img": ''},
				{ "img": ''},],
		},
		{
			id: "5",
			preview: '',
			title: "Cactus plants",
			description: "Cambo Cactus plants with diffrent color pots",
			oldpr: "$2,799",
			newpr: "$12,900",
			offerprice: "$1,899",
			quantity: "6,456",
			color: "",
			images: [
				{ "img": ''},
				{ "img": ''},
				{ "img": ''}],
		},
		{
			id: "6",
			preview: '',
			title: "Cactus plants",
			description: "Cambo Cactus plants with diffrent color pots",
			oldpr: "$1,799",
			newpr: "$15,900",
			offerprice: "25% Off",
			quantity: "6,456",
			color: "danger",
			images: [
				{ "img": ''},
				{ "img": ''},
				{ "img": ''}],
		},
		{
			id: "7",
			preview: '',
			title: "Ugaoo self water plants",
			description: "Ugaoo mini plant with self watring",
			oldpr: "$1,799",
			newpr: "$1,229",
			offerprice: "72% off",
			quantity: "12,456",
			color: "danger",
			images: [
				{ "img": ''},
				{ "img": ''},
				{ "img": ''}],
		},
		{
			id: "8",
			preview: '',
			title: " Red leaf plants",
			description: "Red leaf color plant with pot",
			oldpr: "$2,799",
			newpr: "$6,900",
			offerprice: "Offer Price $599",
			quantity: "13,456",
			color: "success",
			images: [
				{ "img": ''},
				{ "img": ''},
				{ "img": ''}],
		},
		{
			id: "9",
			preview: '',
			title: "Ugoa big leaf",
			description: "Ugoa green color big leaf with pot",
			oldpr: "$4,799",
			newpr: "$4,900",
			offerprice: "Offer Price $599",
			quantity: "3,456",
			color: "success",
			images: [
				{ "img": ''},
				{ "img": ''},
				{ "img": ''}],
		},

		{
			id: "10",
			preview: '',
			title: "Money plant",
			description: "Money plant with haning pot",
			oldpr: "$2,799",
			newpr: "$6,900",
			offerprice: "Offer Price $599",
			quantity: "10,456",
			color: "success",
			images: [
				{ "img": ''},
				{ "img": ''},
				{ "img": ''}],
		},

		{
			id: "11",
			preview: '',
			title: "Cactus plants",
			description: "Cambo Cactus plants with diffrent color pots",
			oldpr: "$2,799",
			newpr: "$12,900",
			offerprice: "$249",
			quantity: "6,456",
			color: "",
			images: [
				{ "img": '' },
				{ "img": '' },
				{ "img": '' }],
		},
		{
			id: "12",
			preview: '',
			title: "Cactus plants",
			description: "Cambo Cactus plants with diffrent color pots",
			oldpr: "$1,799",
			newpr: "$15,900",
			offerprice: "25% Off",
			quantity: "6,456",
			color: "danger",
			images: [
				{ "img": ''},
				{ "img": ''},
				{ "img": ''}],
		},
	],

};
// Import or define Maindata
const Maindata: { id: string; preview: string; title: string; description: string; oldpr: string; newpr: string; offerprice: string; quantity: string; color: string; images: { img: string }[] }[] = [
	// Add your data here
];

export default function reducer(state = initialState, action: any) {
	const { type, payload } = action;

	switch (type) {

		case "ThemeChanger":
			state = payload;
			return state;
			break;

		case "ADD_TO_CART":
			state.ecommercedata = Maindata.filter((idx: any) => {
				return idx.id == payload;
			});

			return state;

		case "PRODUCT":
			state.ecommercedata = state.ecommercedata.filter((idx) => {
				return idx.id == payload;
			});
			return state;
			break;

		default:
			return state;
	}
}
