import React, { useRef, useState } from "react";
import styled from "styled-components";

// Styled Components
const OTPContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;

`;

const OTPInputBox = styled.input`
  border: 1px solid #ededed; /* Slate-500 border color */
  padding: 20px 4px;
  font-size: 30px;
  text-align: center;
  outline: none;
  border-radius: 5px;
  width: 100%;
  color: #ff9800;
  font-weight: 500;

  &:focus {
    border-color: #ff9800; /* Blue-600 border color */
  }
  @media (max-width: 640px) {
    font-size: 20px;
    padding: 5px 5px;
    width:100%;
  }
`;

// Props Interface
interface OTPInputProps {
  length?: number;
  onComplete: (otp: string) => void;
}

const OTPInput: React.FC<OTPInputProps> = ({ length = 4, onComplete }) => {
  const inputRef = useRef<Array<HTMLInputElement | null>>([]);
  const [OTP, setOTP] = useState<string[]>(new Array(length).fill(""));

  const handleTextChange = (input: string, index: number) => {
    const newOTP = [...OTP];
    newOTP[index] = input;
    setOTP(newOTP);

    if (input.length === 1 && index < length - 1) {
      inputRef.current[index + 1]?.focus();
    }

    if (input.length === 0 && index > 0) {
      inputRef.current[index - 1]?.focus();
    }

    if (newOTP.every((digit) => digit !== "")) {
      onComplete(newOTP.join(""));
    }
  };

  return (
    <OTPContainer>
      {Array.from({ length }, (_, index) => (
        <OTPInputBox
          key={index}
          type="text"
          maxLength={1}
          value={OTP[index]}
          onChange={(e) => handleTextChange(e.target.value, index)}
          ref={(ref) => (inputRef.current[index] = ref)}
        />
      ))}
    </OTPContainer>
  );
};

export default OTPInput;
