import React, { FC, Fragment, useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Dropdown,
  InputGroup,
  ListGroup,
  Modal,
  Nav,
  Offcanvas,
  Tab,
} from "react-bootstrap";
import SimpleBar from "simplebar-react";
import { MENUITEMS } from "../sidebar/sidemenu";
import DatePicker from "react-datepicker";
import store from "../../../redux/store";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { ThemeChanger } from "../../../redux/action";
//IMAGES
import desktoplogo from "../../../assets/images/brand-logos/desktop-logo.png";
import togglelogo from "../../../assets/images/brand-logos/toggle-logo.png";
import desktopdark from "../../../assets/images/brand-logos/desktop-dark.png";
import toggledark from "../../../assets/images/brand-logos/toggle-dark.png";
import spainflag from "../../../assets/images/flags/spain_flag.jpg";
import usflag from "../../../assets/images/flags/us_flag.jpg";
import frenchflag from "../../../assets/images/flags/french_flag.jpg";
import germanyflag from "../../../assets/images/flags/germany_flag.jpg";
import russiaflag from "../../../assets/images/flags/russia_flag.jpg";
import italyflag from "../../../assets/images/flags/italy_flag.jpg";

import faces1 from "../../../assets/images/faces/1.jpg";

import faces2 from "../../../assets/images/faces/2.jpg";
import faces8 from "../../../assets/images/faces/8.jpg";

import png1 from "../../../assets/images/ecommerce/png/1.png";
import png15 from "../../../assets/images/ecommerce/png/15.png";
import png40 from "../../../assets/images/ecommerce/png/40.png";
import png8 from "../../../assets/images/ecommerce/png/8.png";
import png11 from "../../../assets/images/ecommerce/png/11.png";
import useOrgLogo from "../../../hooks/useOrgLogo";
import { IMAGE_BASE_URL } from "../../../config";

interface HeaderProps {}

const Header: FC<HeaderProps> = ({ local_varaiable, ThemeChanger }: any) => {
  
  const { logoData: orgLogo } = useOrgLogo();
  const logoUrl = orgLogo ? `${IMAGE_BASE_URL}${orgLogo}` : faces1;
  const firstName = localStorage.getItem("org_name") || "";
  const [startDatei, setStartDatei] = useState(new Date());
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [show1, setShow1] = useState(false);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [show3, setShow3] = useState(false);

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  function menuClose() {
    const theme = store.getState();
    if (window.innerWidth <= 992) {
      ThemeChanger({ ...theme, toggled: "close" });
    }
    if (window.innerWidth >= 992) {
      ThemeChanger({
        ...theme,
        toggled: local_varaiable.toggled ? local_varaiable.toggled : "",
      });
    }
  }
  //Dark Model
  const ToggleDark = () => {
    ThemeChanger({
      ...local_varaiable,
      dataThemeMode: local_varaiable.dataThemeMode == "dark" ? "light" : "dark",
      dataHeaderStyles:
        local_varaiable.dataThemeMode == "dark" ? "light" : "gradient",
      dataMenuStyles:
        local_varaiable.dataNavLayout == "horizontal"
          ? local_varaiable.dataThemeMode == "dark"
            ? "gradient"
            : "dark"
          : "dark",
    });
    const theme = store.getState();

    if (theme.dataThemeMode != "dark") {
      ThemeChanger({
        ...theme,
        bodyBg: "",
        Light: "",
        darkBg: "",
        inputBorder: "",
        dataHeaderStyles: "gradient",
      });
      localStorage.removeItem("velvetdarktheme");
      localStorage.removeItem("darkBgRGB1");
      localStorage.removeItem("darkBgRGB2");
      localStorage.removeItem("darkBgRGB3");
      localStorage.removeItem("darkBgRGB4");
      localStorage.removeItem("velvetMenu");
      localStorage.removeItem("velvetHeader");
    } else {
      localStorage.setItem("velvetdarktheme", "dark");
      localStorage.removeItem("velvetlighttheme");
      localStorage.removeItem("velvetHeader");
      localStorage.removeItem("velvetMenu");
    }
  };

  const [_menuitems, setMenuitems] = useState(MENUITEMS);

  function closeMenuFn() {
    const closeMenuRecursively = (items: any[]) => {
      items?.forEach((item: { active: boolean; children: any }) => {
        item.active = false;
        closeMenuRecursively(item.children);
      });
    };
    closeMenuRecursively(MENUITEMS);
    setMenuitems((arr: any) => [...arr]);
  }
  const toggleSidebar = () => {
    const theme = store.getState();
    const sidemenuType = theme.dataNavLayout;
    if (window.innerWidth >= 992) {
      if (sidemenuType === "vertical") {
        const verticalStyle = theme.dataVerticalStyle;
        const navStyle = theme.dataNavStyle;
        switch (verticalStyle) {
          // closed
          case "closed":
            ThemeChanger({ ...theme, dataNavStyle: "" });
            if (theme.toggled === "close-menu-close") {
              ThemeChanger({ ...theme, toggled: "" });
            } else {
              ThemeChanger({ ...theme, toggled: "close-menu-close" });
            }
            break;
          // icon-overlay
          case "overlay":
            ThemeChanger({ ...theme, dataNavStyle: "" });
            if (theme.toggled === "icon-overlay-close") {
              ThemeChanger({ ...theme, toggled: "", iconOverlay: "" });
            } else {
              if (window.innerWidth >= 992) {
                ThemeChanger({
                  ...theme,
                  toggled: "icon-overlay-close",
                  iconOverlay: "",
                });
              }
            }
            break;
          // icon-text
          case "icontext":
            ThemeChanger({ ...theme, dataNavStyle: "" });
            if (theme.toggled === "icon-text-close") {
              ThemeChanger({ ...theme, toggled: "" });
            } else {
              ThemeChanger({ ...theme, toggled: "icon-text-close" });
            }
            break;
          // doublemenu
          case "doublemenu":
            ThemeChanger({ ...theme, dataNavStyle: "" });
            if (theme.toggled === "double-menu-open") {
              ThemeChanger({ ...theme, toggled: "double-menu-close" });
            } else {
              const sidemenu = document.querySelector(
                ".side-menu__item.active"
              );
              if (sidemenu) {
                if (sidemenu.nextElementSibling) {
                  sidemenu.nextElementSibling.classList.add(
                    "double-menu-active"
                  );
                  ThemeChanger({ ...theme, toggled: "double-menu-open" });
                } else {
                  ThemeChanger({ ...theme, toggled: "double-menu-close" });
                }
              }
            }

            break;
          // detached
          case "detached":
            if (theme.toggled === "detached-close") {
              ThemeChanger({ ...theme, toggled: "" });
            } else {
              ThemeChanger({
                ...theme,
                toggled: "detached-close",
                iconOverlay: "",
              });
            }
            break;
          // default
          case "default":
            ThemeChanger({ ...theme, toggled: "" });
        }
        switch (navStyle) {
          case "menu-click":
            if (theme.toggled === "menu-click-closed") {
              ThemeChanger({ ...theme, toggled: "" });
            } else {
              ThemeChanger({ ...theme, toggled: "menu-click-closed" });
            }
            break;
          // icon-overlay
          case "menu-hover":
            if (theme.toggled === "menu-hover-closed") {
              ThemeChanger({ ...theme, toggled: "" });
              closeMenuFn();
            } else {
              ThemeChanger({ ...theme, toggled: "menu-hover-closed" });
              // setMenuUsingUrl();
            }
            break;
          case "icon-click":
            if (theme.toggled === "icon-click-closed") {
              ThemeChanger({ ...theme, toggled: "" });
            } else {
              ThemeChanger({ ...theme, toggled: "icon-click-closed" });
            }
            break;
          case "icon-hover":
            if (theme.toggled === "icon-hover-closed") {
              ThemeChanger({ ...theme, toggled: "" });
              closeMenuFn();
            } else {
              ThemeChanger({ ...theme, toggled: "icon-hover-closed" });
              // setMenuUsingUrl();
            }
            break;
        }
      }
    } else {
      if (theme.toggled === "close") {
        ThemeChanger({ ...theme, toggled: "open" });

        setTimeout(() => {
          if (theme.toggled == "open") {
            const overlay = document.querySelector("#responsive-overlay");

            if (overlay) {
              overlay.classList.add("active");
              overlay.addEventListener("click", () => {
                const overlay = document.querySelector("#responsive-overlay");

                if (overlay) {
                  overlay.classList.remove("active");
                  menuClose();
                }
              });
            }
          }

          window.addEventListener("resize", () => {
            if (window.screen.width >= 992) {
              const overlay = document.querySelector("#responsive-overlay");

              if (overlay) {
                overlay.classList.remove("active");
              }
            }
          });
        }, 100);
      } else {
        ThemeChanger({ ...theme, toggled: "close" });
      }
    }
  };
  /****fullscreeen */
  const [fullScreen, setFullScreen] = useState(false);

  const toggleFullScreen = () => {
    const elem = document.documentElement;

    if (!document.fullscreenElement) {
      elem.requestFullscreen().then(() => setFullScreen(true));
    } else {
      document.exitFullscreen().then(() => setFullScreen(false));
    }
  };

  const handleFullscreenChange = () => {
    setFullScreen(!!document.fullscreenElement);
  };

  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  const Switchericon = () => {
    document.querySelector(".offcanvas-end")?.classList.toggle("show");
    if (
      document.querySelector(".switcher-backdrop")?.classList.contains("d-none")
    ) {
      document.querySelector(".switcher-backdrop")?.classList.add("d-block");
      document.querySelector(".switcher-backdrop")?.classList.remove("d-none");
    }
  };

  const cartProduct = [
    {
      id: 1,
      src: png1,
      name: "Cactus mini plant",
      quantity: "02",
      price: "$1,299.00",
      oldpr: "$1,799",
      newpr: "$1,229",
    },
    {
      id: 2,
      src: png15,
      name: "Sports shoes for men",
      quantity: "01",
      price: "$179.29",
      oldpr: "$799",
      newpr: "$10,229",
    },
    {
      id: 3,
      src: png40,
      name: "Pink color smart watch",
      quantity: "03",
      oldpr: "$599",
      newpr: "$5,500",
    },
    {
      id: 4,
      src: png8,
      name: "Red Leafs plant",
      quantity: "01",
      oldpr: "$799",
      newpr: "$15,300",
    },
    {
      id: 5,
      src: png11,
      name: "Good luck mini plant",
      quantity: "02",
      oldpr: "$99",
      newpr: "$600",
    },
  ];

  const [cartItems, setCartItems] = useState([...cartProduct]);
  const [cartItemCount, setCartItemCount] = useState(cartProduct.length);

  const handleRemove = (itemId: number) => {
    const updatedCart = cartItems.filter((item) => item.id !== itemId);
    setCartItems(updatedCart);
    setCartItemCount(updatedCart.length);
  };

  const img1 = <img src={faces2} alt="" />;
  const img2 = <img src={faces8} alt="" />;

  const initialNotifications = [
    {
      id: 1,
      src: img1,
      icon: "",
      name: "Olivia James",
      text1: "Congratulate for New template start",
      text2: "",
      text3: "2 min ago",
      avatarcolor: "secondary",
    },
    {
      id: 2,
      src: "",
      icon: "bx bx-pyramid fs-18",
      name: "Order Placed",
      text1: "Order Placed Successfully",
      text2: "ID: #1116773",
      text3: "5 min ago",
      avatarcolor: "warning",
    },
    {
      id: 3,
      src: img2,
      icon: "",
      name: "Elizabeth Lewis",
      text1: "added new schedule realease date",
      text2: "",
      text3: "10 min ago",
      avatarcolor: "secondary",
    },
    {
      id: 4,
      src: "",
      icon: "bx bx-pulse fs-18",
      name: "Your Order Has Been Shipped",
      text1: "Order No: 123456 Has Shipped To Your Delivery Address",
      text2: "",
      text3: "12 min ago",
      avatarcolor: "primary",
    },
    {
      id: 5,
      src: "",
      icon: "bx bx-badge-check",
      name: "Account Has Been Verified",
      text1: "Your Account Has Been Verified Sucessfully",
      text2: "",
      text3: "20 min ago",
      avatarcolor: "pink",
    },
  ];

  const [notifications, setNotifications] = useState([...initialNotifications]);

  const handleNotificationClose = (index: number) => {
    // Create a copy of the notifications array and remove the item at the specified index
    const updatedNotifications = [...notifications];
    updatedNotifications.splice(index, 1);
    setNotifications(updatedNotifications);
  };

  const searchRef = useRef(null);

  const handleClick = (event: any) => {
    const searchInput: any = searchRef.current;

    if (
      searchInput &&
      (searchInput === event.target || searchInput.contains(event.target))
    ) {
      document.querySelector(".header-search")?.classList.add("searchdrop");
    } else {
      document.querySelector(".header-search")?.classList.remove("searchdrop");
    }
  };

  useEffect(() => {
    document.body.addEventListener("click", handleClick);

    return () => {
      document.body.removeEventListener("click", handleClick);
    };
  }, []);

  // search Functionality
  const [showa, setShowa] = useState(false);
  const [InputValue, setInputValue] = useState("");
  const [show2, setShow2] = useState(false);
  const [searchcolor, setsearchcolor] = useState("text-dark");
  const [searchval, setsearchval] = useState("Type something");
  const [NavData, setNavData] = useState([]);

  const myfunction = (inputvalue: string) => {
    document.querySelector(".search-result")?.classList.remove("d-none");

    const i: any = [];
    const allElement2: any = [];

    MENUITEMS.forEach((mainLevel) => {
      if (mainLevel.children) {
        setShowa(true);
        mainLevel.children.forEach((subLevel: any) => {
          i.push(subLevel);
          if (subLevel.children) {
            subLevel.children.forEach((subLevel1: any) => {
              i.push(subLevel1);
              if (subLevel1.children) {
                subLevel1.children.forEach((subLevel2: any) => {
                  i.push(subLevel2);
                });
              }
            });
          }
        });
      }
    });

    for (const allElement of i) {
      if (allElement.title.toLowerCase().includes(inputvalue.toLowerCase())) {
        if (
          allElement.title.toLowerCase().startsWith(inputvalue.toLowerCase())
        ) {
          setShow2(true);

          // Check if the element has a path and doesn't already exist in allElement2 before pushing
          if (
            allElement.path &&
            !allElement2.some(
              (el: { title: any }) => el.title === allElement.title
            )
          ) {
            allElement2.push(allElement);
          }
        }
      }
    }

    if (!allElement2.length || inputvalue === "") {
      if (inputvalue === "") {
        setShow2(false);
        setsearchval("Type something");
        setsearchcolor("text-dark");
      }
      if (!allElement2.length) {
        setShow2(false);
        setsearchcolor("text-danger");
        setsearchval("There is no component with this name");
      }
    }
    setNavData(allElement2);
  };

  //   sticky-pin
  const Topup = () => {
    if (window.scrollY > 30 && document.querySelector(".app-header")) {
      const Scolls = document.querySelectorAll(".app-header");
      Scolls.forEach((e) => {
        e.classList.add("sticky-pin");
      });
    } else {
      const Scolls = document.querySelectorAll(".app-header");
      Scolls.forEach((e) => {
        e.classList.remove("sticky-pin");
      });
    }
  };
  if (typeof window !== "undefined") {
    window.addEventListener("scroll", Topup);
  }

  return (
    <Fragment>
      <header className="app-header">
        <div className="main-header-container container-fluid">
          <div className="header-content-left">
            <div className="header-element">
              <div className="horizontal-logo">
                <Link
                  to={`${import.meta.env.BASE_URL}dashboards/Usersetup/`}
                  className="header-logo"
                >
                  <img src={desktoplogo} alt="logo" className="desktop-logo" />
                  <img src={togglelogo} alt="logo" className="toggle-logo" />
                  <img src={desktopdark} alt="logo" className="desktop-dark" />
                  <img src={toggledark} alt="logo" className="toggle-dark" />
                </Link>
              </div>
            </div>

            <div className="header-element">
              <Link
                aria-label="anchor"
                to="#"
                className="sidemenu-toggle header-link"
                data-bs-toggle="sidebar"
                onClick={() => toggleSidebar()}
              >
                <span className="open-toggle me-2">
                  <i className="bx bx-menu header-link-icon"></i>
                </span>
              </Link>
            </div>
            <div
              className="header-element header-search d-lg-none d-block"
              onClick={handleShow3}
            >
              <Link
                aria-label="anchor"
                to="#"
                className="header-link"
                data-bs-toggle="modal"
                data-bs-target="#searchModal"
              >
                <i className="bx bx-search-alt-2 header-link-icon"></i>
              </Link>
            </div>
          </div>

          <div className="header-content-right">
            <Dropdown className="header-element country-selector">
              <Dropdown.Toggle
                variant=""
                aria-label="anchor"
                className="header-link dropdown-toggle"
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
              >
                <i className="bx bx-globe header-link-icon"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu
                as="ul"
                className="main-header-dropdown dropdown-menu border-0"
                data-popper-placement="none"
              >
                <Dropdown.Item className=" d-flex align-items-center border-0">
                  <span className="avatar avatar-xs lh-1 me-2">
                    <img src={usflag} alt="img" />
                  </span>
                  English
                </Dropdown.Item>
                <Dropdown.Item className=" d-flex align-items-center border-0">
                  <span className="avatar avatar-xs lh-1 me-2">
                    <img src={spainflag} alt="img" />
                  </span>
                  Spanish
                </Dropdown.Item>
                <Dropdown.Item className=" d-flex align-items-center border-0">
                  <span className="avatar avatar-xs lh-1 me-2">
                    <img src={frenchflag} alt="img" />
                  </span>
                  French
                </Dropdown.Item>
                <Dropdown.Item className=" d-flex align-items-center border-0">
                  <span className="avatar avatar-xs lh-1 me-2">
                    <img src={germanyflag} alt="img" />
                  </span>
                  German
                </Dropdown.Item>
                <Dropdown.Item className=" d-flex align-items-center border-0">
                  <span className="avatar avatar-xs lh-1 me-2">
                    <img src={italyflag} alt="img" />
                  </span>
                  Italian
                </Dropdown.Item>
                <Dropdown.Item className=" d-flex align-items-center">
                  <span className="avatar avatar-xs lh-1 me-2">
                    <img src={russiaflag} alt="img" />
                  </span>
                  Russian
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <div className="header-element header-theme-mode">
              <Link
                aria-label="anchor"
                to="#"
                className="header-link layout-setting"
                onClick={() => ToggleDark()}
              >
                <i className="bx bx-sun bx-flip-horizontal header-link-icon ionicon  dark-layout"></i>

                <i className="bx bx-moon bx-flip-horizontal header-link-icon ionicon light-layout"></i>
              </Link>
            </div>

            <Dropdown
              className="header-element cart-dropdown"
              autoClose="outside"
            >
              <Dropdown.Toggle
                variant=""
                className="header-link dropdown-toggle"
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
              >
                <i className="bx bx-cart header-link-icon ionicon"></i>
                <span
                  className="badge bg-danger rounded-pill header-icon-badge"
                  id="cart-icon-badge"
                >
                  {" "}
                  {cartItemCount}
                </span>
              </Dropdown.Toggle>
              <Dropdown.Menu
                align="end"
                className="main-header-dropdown dropdown-menu  border-0 dropdown-menu-end"
                data-popper-placement="none"
              >
                <div className="p-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="mb-0 fs-17 fw-semibold">Cart Items</p>
                    <span
                      className="badge bg-success-transparent"
                      id="cart-data"
                    >
                      {" "}
                      {cartItemCount} Item{cartItemCount !== 1 ? "s" : ""}
                    </span>
                  </div>
                </div>
                <div>
                  <hr className="dropdown-divider" />
                </div>
                <ul className="list-unstyled mb-0">
                  <SimpleBar id="header-cart-items-scroll">
                    {cartItems.map((idx) => (
                      <Dropdown.Item as="li" key={Math.random()}>
                        <div className="d-flex align-items-start cart-">
                          <span className="avatar avatar-xl bd-gray-200 p-1">
                            <img src={idx.src} alt="" />
                          </span>
                          <div className="flex-grow-1 ms-3">
                            <div className="d-flex align-items-start justify-content-between mb-0">
                              <div className=" fs-13 fw-semibold">
                                <Link
                                  to={`${
                                    import.meta.env.BASE_URL
                                  }pages/ecommerce/cart`}
                                >
                                  {idx.name}
                                </Link>
                              </div>
                              <div>
                                <Link
                                  aria-label="anchor"
                                  to="#"
                                  className="header-cart-remove float-end -close"
                                  onClick={() => handleRemove(idx.id)}
                                >
                                  <i className="ti ti-trash"></i>
                                </Link>
                              </div>
                            </div>
                            <div className="min-w-fit-content align-items-start">
                              <div className=" fw-normal fs-12 text-muted">
                                Quantity:{idx.quantity}
                              </div>
                              <div className="d-flex  align-items-center">
                                <span className="fw-semibold fs-16">
                                  {idx.newpr}
                                </span>
                                <p className="text-muted text-decoration-line-through ms-1 op-6 fs-12 mb-0">
                                  {idx.oldpr}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Dropdown.Item>
                    ))}
                  </SimpleBar>
                </ul>
                <div
                  className={`p-3 empty-header-item border-top ${
                    cartItemCount === 0 ? "d-none" : "d-block"
                  }`}
                >
                  <div className="d-grid">
                    <Link
                      to={`${import.meta.env.BASE_URL}pages/ecommerce/checkout`}
                      className="btn btn-primary"
                    >
                      Checkout
                    </Link>
                  </div>
                </div>
                <div
                  className={`p-5 empty-item  ${
                    cartItemCount === 0 ? "d-block" : "d-none"
                  }`}
                >
                  <div className="text-center">
                    <span className="avatar avatar-xxl avatar-rounded bg-warning-transparent">
                      <i className="bx bx-cart bx-tada fs-2"></i>
                    </span>
                    <h6 className="fw-bold mb-2 mt-3">Your Cart is Empty</h6>
                    <Link
                      to={`${import.meta.env.BASE_URL}pages/ecommerce/products`}
                      className="btn btn-primary btn-wave btn-sm m-1"
                      data-abc="true"
                    >
                      continue shopping{" "}
                      <i className="bi bi-arrow-right ms-1"></i>
                    </Link>
                  </div>
                </div>
              </Dropdown.Menu>
            </Dropdown>

            <div className="header-element header-fullscreen">
              <Link
                aria-label="anchor"
                onClick={toggleFullScreen}
                to="#"
                className="header-link"
              >
                {fullScreen ? (
                  <i className="bx bx-exit-fullscreen header-link-icon  full-screen-close"></i>
                ) : (
                  <i className="bx bx-fullscreen header-link-icon  full-screen-open"></i>
                )}
              </Link>
            </div>

            {/* <div className="header-element d-flex header-settings" onClick={handleShow1}>
							<Link aria-label="anchor" to="#" className=" header-link nav-link icon me-1" data-bs-toggle="offcanvas" data-bs-target="#sidebar-right" aria-controls="sidebar-right">
								<i className="bx bx-slider header-link-icon"></i>
							</Link>
						</div> */}

            <Dropdown className="header-element mainuserProfile">
              <Dropdown.Toggle
                variant=""
                as="a"
                className="header-link dropdown-toggle"
                id="mainHeaderProfile"
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
                aria-expanded="false"
              >
                <div className="d-flex align-items-center">
                  <div className="d-sm-flex wd-100p">
                    <div className="avatar avatar-sm">
                      <img
                        alt="avatar"
                        className="rounded-circle"
                        crossOrigin="anonymous"
                        // src={orgLogo ? orgLogo : faces1}
                        src={logoUrl}
                        // src={faces1}
                      />
                    </div>
                    <div className="ms-2 my-auto d-none d-xl-flex">
                      <h6 className=" font-weight-semibold mb-0 fs-13 user-name d-sm-block d-none">
                      {firstName} 
                      </h6>
                    </div>
                  </div>
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu
                as="ul"
                className="dropdown-menu  border-0 main-header-dropdown  overflow-hidden header-profile-dropdown"
                aria-labelledby="mainHeaderProfile"
              >
                <Dropdown.Item as="li" className="border-0">
                  <Link to={`${import.meta.env.BASE_URL}pages/profile`}>
                    <i className="fs-13 me-2 bx bx-user"></i>Profile
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item as="li" className="border-0">
                  <Link to={`${import.meta.env.BASE_URL}pages/email/mailapp`}>
                    <i className="fs-13 me-2 bx bx-comment"></i>Message
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item as="li" className="border-0">
                  <Link
                    to={`${import.meta.env.BASE_URL}pages/email/mailsettings`}
                  >
                    <i className="fs-13 me-2 bx bx-cog"></i>Settings
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item as="li" className="border-0">
                  <Link to={`${import.meta.env.BASE_URL}pages/faqs`}>
                    <i className="fs-13 me-2 bx bx-help-circle"></i>Help
                  </Link>
                </Dropdown.Item>
                <Dropdown.Item as="li" className="border-0">
                  <Link
                    to={"/signin"}
                  >
                    <i className="fs-13 me-2 bx bx-arrow-to-right"></i>Log Out
                  </Link>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <div className="header-element">
              <Link
                aria-label="anchor"
                to="#"
                className="header-link switcher-icon ms-1"
                data-bs-toggle="offcanvas"
                data-bs-target="#switcher-canvas"
                onClick={() => Switchericon()}
              >
                <i className="bx bx-cog bx-spin header-link-icon"></i>
              </Link>
            </div>
          </div>
        </div>
      </header>

      <Modal
        className="fade"
        id="searchModal"
        show={show3}
        onHide={handleClose3}
        tabIndex={-1}
        aria-labelledby="searchModal"
        aria-hidden="true"
      >
        {/* <Modal.Dialog> */}
        <div className="modal-content">
          <Modal.Body>
            <InputGroup>
              <input
                type="search"
                className="form-control px-2 "
                placeholder="Search..."
                aria-label="Username"
              />
              <Link
                to="#"
                className="input-group-text bg-primary text-fixed-white"
                id="Search-Grid"
              >
                <i className="fe fe-search header-link-icon fs-18"></i>
              </Link>
            </InputGroup>
            <div className="mt-3">
              <div className="">
                <p className="fw-semibold text-muted mb-2 fs-13">
                  Recent Searches
                </p>
                <div className="ps-2">
                  <Link to="#" className="search-tags">
                    <i className="fe fe-search me-2"></i>People<span></span>
                  </Link>
                  <Link to="#" className="search-tags">
                    <i className="fe fe-search me-2"></i>Pages<span></span>
                  </Link>
                  <Link to="#" className="search-tags">
                    <i className="fe fe-search me-2"></i>Articles<span></span>
                  </Link>
                </div>
              </div>
              <div className="mt-3">
                <p className="fw-semibold text-muted mb-2 fs-13">
                  Apps and pages
                </p>
                <ul className="ps-2">
                  <li className="p-1 d-flex align-items-center text-muted mb-2 search-app">
                    <Link to={`${import.meta.env.BASE_URL}apps/fullcalendar/`}>
                      <span>
                        <i className="bx bx-calendar me-2 fs-14 bg-primary-transparent p-2 rounded-circle "></i>
                        Calendar
                      </span>
                    </Link>
                  </li>
                  <li className="p-1 d-flex align-items-center text-muted mb-2 search-app">
                    <Link
                      to={`${import.meta.env.BASE_URL}pages/email/mailapp/`}
                    >
                      <span>
                        <i className="bx bx-envelope me-2 fs-14 bg-primary-transparent p-2 rounded-circle"></i>
                        Mail
                      </span>
                    </Link>
                  </li>
                  <li className="p-1 d-flex align-items-center text-muted mb-2 search-app">
                    <Link to={`${import.meta.env.BASE_URL}uielements/buttons/`}>
                      <span>
                        <i className="bx bx-dice-1 me-2 fs-14 bg-primary-transparent p-2 rounded-circle "></i>
                        Buttons
                      </span>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="mt-3">
                <p className="fw-semibold text-muted mb-2 fs-13">Links</p>
                <ul className="ps-2">
                  <li className="p-1 align-items-center  mb-1 search-app">
                    <Link to="#" className="text-primary">
                      <u>http://spruko/html/spruko.com</u>
                    </Link>
                  </li>
                  <li className="p-1 align-items-center mb-1 search-app">
                    <Link to="#" className="text-primary">
                      <u>http://spruko/demo/spruko.com</u>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </Modal.Body>
          <div className="modal-footer d-block">
            <div className="text-center">
              <Link
                to="#"
                className="text-primary text-decoration-underline fs-15"
              >
                View all results
              </Link>
            </div>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  local_varaiable: state,
});
export default connect(mapStateToProps, { ThemeChanger })(Header);
