import styled from "styled-components";

interface StyledComponentProps {
  isAlternate?: boolean;
}

export const StyledDiv = styled.div`
  text-align: center;
`;

export const StyledLogo = styled.img`
  margin: 0% 0 4% 0;
  width: 154px;
  height: 63px;
`;

export const StyledContainer = styled.div`
  width: 400px;
  margin: 2% auto 0 auto;
  border: 1px solid #ededed;
  border-radius: 10px;
  box-shadow: 5px 5px 25px 5px #0000000d;
  background: #fff;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  @media (max-width:550px){
    width:100%;
  }
`;

export const StyledHeading = styled.div`
  color: #034737;
  font-size: 24px;
  font-family: ;
  font-weight: bold;
  line-height: 28.2px;
  @media (max-width:463px){
    text-align: center;
  }
`;

export const StyledText = styled.p<StyledComponentProps>`
  color: ${(props) => (props.isAlternate ? "#000000" : "#909090")};
  font-size: 14px;
  text-align: center;
  font-family: ;
  font-weight: 400;
  margin-top: -2%;

  span {
    text-decoration: underline;
    cursor: pointer;
    color: #ff9800;
    font-weight: 600;
  }
`;

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-self: flex-start;
  width: 100%;
`;

export const StyledLabel = styled.label`
  font-size: 14px
  font-weight: 500;
  cursor: pointer;
  color: #034737;
  align-self: flex-start;
  display: flex; 
  justify-content: space-between;
  width: 100%;

   span{
    color: #FF9800;
    font-size: 14px;
   }

`;

export const StyledInput = styled.input`
  width: 100%;
  border: 1px solid #ededed;
  background: #ffffff;
  padding: 10px;
  border-radius: 5px;
  align-self: flex-start;

  &::placeholder {
    color: #c0c0c0;
    font-size: 12px;
  }

  &:focus {
    outline: none;
    border: 1px solid #ff9800;
  }
`;

export const StyledButton = styled.button`
  background-color: #013e30;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  font-family: ;
  width: 100%;
  margin-top: 2%;
`;

export const StyledCheckboxWrapper = styled.div`
  display: flex;
  align-items: flex-start;

  input[type="checkbox"] {
    margin-right: 0.5rem;
    width: 1rem;
    height: 1rem;
    accent-color: #ff9800; /* Orange color for the checkbox */
    cursor: pointer;
    background: white;

    &:checked {
      color: white;
    }
  }

  label {
    font-size: 12px;
    color: #909090;
    line-height: 1.4;
    cursor: pointer;
    font-weight: 500;
  }
`;
