import React, { useState, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../layout";
import {
  StyledContainer,
  StyledHeading,
  StyledText,
  StyledColumn,
  StyledInput,
  StyledLabel,
  StyledButton,
  StyledCheckboxWrapper,
  StyledLogo,
  StyledDiv,
} from "./styles";
import logo from "../../assets/images/layout/clinicLogo.png";
import { post } from "../../services/apiService";
// import { useSnackbar } from "../../services/snackbarContext";
import {toast, ToastContainer} from "react-toastify";
interface ApiResponse<T> {
  status: string;
  message: string;
  data?: T;
}

const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();
  // const { showSnackbar } = useSnackbar();
  const [email, setEmail] = useState<string>("");

  const handleLogin = async  (): Promise<void> => {
    try {
      const response = await post<ApiResponse<null>>("/check-email", { email });
      if (response.status === "00") {
        toast.success("Password reset link has been sent to your email address");
        // navigate("/forgot/resetreq", { state: { email } });
      } else {
        toast.error(
          "Email doesn't exist in the system. Please try again."
         
        );
      }
    } catch (error: any) {
      toast.error(error.message || "An error occurred");
    }
  };

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  return (
    <Layout>
      <ToastContainer position="top-right" autoClose={5000} />
      <StyledDiv>
        <StyledLogo src={logo} alt="Clinic Logo" />
      </StyledDiv>
      <StyledContainer>
        <StyledHeading>Forgot Your Password</StyledHeading>
        <StyledText>
          Enter your registered email address and we will send you instructions
          to reset your password
        </StyledText>
        <StyledColumn>
          <StyledLabel>Email Address</StyledLabel>
          <StyledInput
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={handleEmailChange}
          />
        </StyledColumn>

        <StyledButton onClick={handleLogin}>Submit</StyledButton>
        <StyledText isAlternate={true}>
          Know your password?{" "}
          <span onClick={() => navigate("/signin")}>Login</span>
        </StyledText>
      </StyledContainer>
    
    </Layout>
  );
};

export default ForgotPassword;
