import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Layout from "../../../pages/layout";
import SubscriptionForm from "../subscriptionForm/subscriptionForm";
import DynamicStepper from "../../common/stepper/stepper";
import Payment from "../../../pages/Payment/Payment";
import Otp from "../../../pages/OtpScreen/Otp";

const Subscription: React.FC = () => {
  const steps: string[] = ["Personal Details", "Payment", "Authentication", "Complete"];
  const components: React.ComponentType<{ activeStep: number; setActiveStep: (step: number) => void }>[] = [
    SubscriptionForm,
    Payment,
    Otp

  ];

  const [searchParams] = useSearchParams();
  const [initialStep, setInitialStep] = useState<number>(0);

  // Set initial step based on the query parameter from the URL (if available)
  useEffect(() => {
    const stepParam = searchParams.get("step");
    if (stepParam) {
      const parsedStep = parseInt(stepParam, 10);
      if (!isNaN(parsedStep) && parsedStep >= 0 && parsedStep < steps.length) {
        setInitialStep(parsedStep); // Parse and validate the step from query param
      }
    }
  }, [searchParams, steps.length]);

  return (
    <Layout showCancelButton={initialStep === 0 || initialStep === 1}>
      <div className="col-10">
      <DynamicStepper
        steps={steps}
        components={components}
        initialStep={initialStep} 
      />
      </div>
    
    </Layout>
  );
};

export default Subscription;

