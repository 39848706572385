import React, { useEffect, useRef } from 'react';
import { PayPalService } from '../../services/paypal.service';
import { usePayPalSDK } from '../../hooks/usePayPalSDK';
import { PaymentProps } from '../../types/payment.types';

const PAYPAL_CLIENT_ID = 'AZdC6PWERVJTVRd0hGeuls1QEDT9emcHVTZ6XD_aPa4zJ9X5LMD3wnzvYpYIdc1SWorwMcLbiHvf5XQU';

export const PayPalButton: React.FC<PaymentProps> = ({
  amount,
  currency = 'USD',
  onPaymentSuccess,
  onPaymentError,
}) => {
  const buttonRef = useRef<HTMLDivElement>(null);
  const { isScriptLoaded, error } = usePayPalSDK({
    clientId: PAYPAL_CLIENT_ID,
    currency,
  });

  useEffect(() => {
    const renderButton = async () => {
      if (isScriptLoaded && buttonRef.current && window.paypal?.Buttons) {
        try {
          buttonRef.current.innerHTML = '';
          
          const buttons = window.paypal.Buttons({
            createOrder: () => PayPalService.createOrder(amount, currency),
            onApprove: async (data: { orderID: string }) => {
              try {
                const orderData = await PayPalService.captureOrder(data.orderID);
                onPaymentSuccess?.(orderData.details);
              } catch (err) {
                onPaymentError?.(err instanceof Error ? err : new Error('Payment failed'));
              }
            },
            onError: (err: Error) => {
              onPaymentError?.(err);
            }
          });

          if (buttons.isEligible()) {
            await buttons.render(buttonRef.current);
          }
        } catch (err) {
          onPaymentError?.(err instanceof Error ? err : new Error('Failed to render PayPal button'));
        }
      }
    };

    renderButton();
  }, [isScriptLoaded, amount, currency, onPaymentSuccess, onPaymentError]);

  if (error) {
    return <div className="text-red-500">Failed to load PayPal</div>;
  }

  return <div ref={buttonRef} className="paypal-button" />;
};