//Icons

const icon1 = <i className="bx bx-desktop"></i>;


//Badges
const badge1 = <span className="badge bg-warning-transparent ms-2 d-inline-block">12</span>;

export const MENUITEMS = [
	{
		menutitle: "MAIN",
	},
	{
		title: "Dashboards", icon: icon1, badgetxt: badge1, type: "sub", active: false, selected: false, dirchange: false,
		children: [
			{ path: `${import.meta.env.BASE_URL}dashboards/Usersetup`, type: "link", active: false, selected: false, dirchange: false, title: "User Setup", },
			{ path: `${import.meta.env.BASE_URL}dashboards/crypto`, type: "link", active: false, selected: false, dirchange: false, title: "Manage Clinics" },
			{ path: `${import.meta.env.BASE_URL}dashboards/jobs`, type: "link", active: false, selected: false, dirchange: false, title: "Manage Users" },
			{ path: `${import.meta.env.BASE_URL}dashboards/crm`, type: "link", active: false, selected: false, dirchange: false, title: "Manage Profile" },
			{ path: `${import.meta.env.BASE_URL}dashboards/ecommerce`, type: "link", active: false, selected: false, dirchange: false, title: "Manage System" },
		],
	},
	
	

];
