import styled from "styled-components";

interface StyledComponentProps {
  isAlternate?: boolean;
 
}

export const StyledDiv = styled.div`
  text-align: center;
`;

export const StyledLogo = styled.img`
  width: 76px;
  height: 31px;
`;

export const StyledContainer = styled.div`
  width: 400px;
  margin: 4% auto 0 auto;
  border: 1px solid #ededed;
  border-radius: 10px;
  box-shadow: 5px 5px 25px 5px #0000000d;
  background: #fff;
  padding: 3rem 2rem 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  & > :last-child {
    width: calc(100% + 4rem);
    padding-left: 0;
    padding-right: 0;
  }

  @media (max-width: 550px) {
    width: 100%;
  }
`;

export const StyledHeading = styled.div`
  color: #034737;
  font-size: 24px;
  font-family: ;
  font-weight: bold;
  line-height: 28.2px;
  @media (max-width: 550px) {
    text-align: center;
  }
`;

export const StyledText = styled.p<StyledComponentProps>`
  color: ${(props) => (props.isAlternate ? "#000000" : "#909090")};
  font-size: ${(props) => (props.isAlternate ? "14px" : "12px")};
  text-align: center;
  font-family: ;
  font-weight: 400;

  span {
    text-decoration: underline;
    cursor: pointer;
    color: #ff9800;
    font-weight: 600;
  }
`;

export const StyledButton = styled.button`
  background-color: #013e30;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  font-family: ;
  width: 80%;

  font-weight: 400;
`;

export const StyledDivider = styled.div`
  border: 1px;
  background-color: #00000033;
  width: 98%;
  height: 1px;
`;

export const StyledGreenBar = styled.div`
  background: #034737;
  padding: 4% 0;
  width: 100%;
  color: #fff;
  text-align: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  cursor: pointer;
`;
