import { useState, useEffect } from 'react';
import { post } from '../services/apiService';


const useOrgLogo = () => {
  const [logoData, setLogoData] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  // useEffect(() => {
  //   const fetchLogo = async () => {
  //     try {
  //       const org_name = localStorage.getItem('org_name');
  //       if (!org_name) {
  //         setError('No organization name found');
  //         return;
  //       }
  //       const response = await post<any>("/get-org-logo", { org_name });
  //       if (response.status === "00") {
  //         const blob = response.data; 
  //         const imageUrl = URL.createObjectURL(blob);
  //         setLogoData(imageUrl);
  //         setError(null);
  //       } else {
  //         setError(response.message || "Failed to fetch logo.");
  //         setLogoData(null);
  //       }

  //       // const response = await fetch('http:localhost:3000/api/get-org-logo', {
  //       //   method: 'POST',
  //       //   headers: {
  //       //     'Content-Type': 'application/json',
  //       //   },
  //       //   body: JSON.stringify({ org_name }),
  //       // });

  //       // if (!response.ok) {
  //       //   throw new Error(`HTTP error! status: ${response.status}`);
  //       // }

  //       // const blob = await response.blob();
  //       // const imageUrl = URL.createObjectURL(blob);
  //       // setLogoData(imageUrl);
  //       // setError(null);

  //     } catch (error) {
  //       console.error('Error fetching logo:', error);
  //       setError('Failed to fetch logo');
  //       setLogoData(null);
  //     }
  //   };

  //   fetchLogo();

  //   // Cleanup function to revoke object URL
  //   return () => {
  //     if (logoData) {
  //       URL.revokeObjectURL(logoData);
  //     }
  //   };
  // }, []);

  useEffect(() => {
    const fetchLogo = async () => {
      try {
        const org_name = localStorage.getItem("org_name");
        if (!org_name) {
          setError("No organization name found");
          return;
        }

        const response = await post<any>("/get-org-logo", { org_name });
        if (response.status === "00") {
          setLogoData(response.data);
          setError(null);
        } else {
          setError(response.message || "Failed to fetch logo.");
          setLogoData(null);
        }
      } catch (error: unknown) {
        if (error instanceof Error) {
          console.error("Error fetching logo:", error.message);
          setError(error.message || "Failed to fetch logo.");
        } else {
          console.error("Unexpected error:", error);
          setError("An unexpected error occurred.");
        }
        setLogoData(null);
      }
    };

    fetchLogo();
  }, []);
  return { logoData, error };
};

export default useOrgLogo;