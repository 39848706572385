import React, { FC, Fragment, useState } from "react";

//IMAGES

interface EcommerceProps {}

const Ecommerce: FC<EcommerceProps> = () => {
  const [_content, setContent] = useState("");

  return <Fragment></Fragment>;
};

export default Ecommerce;
