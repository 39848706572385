// src/config/config.ts
interface Config {
    API_BASE_URL: string;
    IMAGE_BASE_URL: string;
  }
  
  const getApiUrl = (): string => {
    if (import.meta.env.MODE === 'production') {
      return 'https://backendapis.qliniq.org/api';
    }
    return 'http://localhost:3000/api'; 
  };

  const getImageUrl = (): string => {
    // Base URL for images, no `/api` prefix
    if (import.meta.env.MODE === 'production') {
      return 'https://backendapis.qliniq.org';
    }
    return 'http://localhost:3000'; // Your static image server URL
  };
  
  export const config: Config = {
    API_BASE_URL: getApiUrl(),
    IMAGE_BASE_URL: getImageUrl(),
  };
  
  export const API_BASE_URL = config.API_BASE_URL;
  export const IMAGE_BASE_URL = config.IMAGE_BASE_URL;