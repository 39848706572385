import axios, { AxiosRequestConfig } from "axios";
// import { API_BASE_URL } from "../constants";
import { API_BASE_URL } from "../config";



const api = axios.create({
  baseURL: API_BASE_URL,
  headers: { "Content-Type": "application/json" },
});

// Generic response typing for the `post` method
export const post = async <T,>(
  endpoint: string,
  data: Record<string, unknown>
): Promise<T> => {
  try {
    console.log('Environment:', import.meta.env.MODE);
    console.log('API Base URL:', API_BASE_URL);
    const response = await api.post<T>(endpoint, data);
    return response.data;
  } catch (error: any) {
    console.error(error);
    throw error;
  }
};

// fileUpload method for uploading files
// export const fileUpload = async (
//   file: File | null,
//   orgName: string,
//   fileName: string = ""
// ): Promise<any> => {
//   // if (!file) {
//   //   throw new Error("File is required for upload.");
//   // }

//   const formData = new FormData();
//   if (file) {
//     formData.append("file", file);
//   } else {
//     throw new Error("File is required for upload.");
//   }

//   if (!file) {
//     // formData.append("file", new Blob(), "default.txt");
//   }

//   const config: AxiosRequestConfig = {
//     headers: {
//       "Content-Type": "multipart/form-data",
//       "org-name": orgName,
//       "file-name": fileName,
//     },
//   };

//   try {
//     const response = await api.post("/upload-file", formData, config);
//     return response.data;
//   } catch (error: any) {
//     console.error("Error uploading file:", error);
//     throw error;
//   }
// };

export const fileUpload = async (file: File | null, orgName: string, fileName = "") => {
  let formData: FormData | undefined;
  if (file) {
    formData = new FormData();
    formData.append("file", file);
  }
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      "org-name": orgName,
      "file-name": fileName,
    },
  };

  try {
    const response = await api.post("/upload-file", formData, config);
    return response.data;
  } catch (error) {
    console.error("Error uploading file:", error);
    throw error;
  }
};


export default api;
