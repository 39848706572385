import React, { FC, Fragment, useEffect, useState } from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import axios from "axios";
import image1 from "../../../assets/images/brand-logos/prof.png";
import back from "../../../assets/images/brand-logos/back.png";
import { cardData } from "./salesdata";
// import image2 from "../../../assets/images/brand-logos/icon1.png";
// import image3 from "../../../assets/images/brand-logos/icon2.png";
// import image4 from "../../../assets/images/brand-logos/icon3.png";
import useOrgLogo from "../../../hooks/useOrgLogo";
import { IMAGE_BASE_URL } from "../../../config";


const Sales: FC = () => {
  const { logoData: orgLogo } = useOrgLogo();
  const orgName = localStorage.getItem("org_name") || "";
  const roleName = localStorage.getItem("role_name") || "";
  // const lastName = localStorage.getItem("last_name") || "";
  const logoUrl = orgLogo ? `${IMAGE_BASE_URL}${orgLogo}` : image1;
  return (
    <Fragment>
      <div
        className="bg-light p-4 rounded shadow-sm mb-4"
        style={{
          position: "relative",
          backgroundImage: `url(${back})`,
          backgroundSize: "cover",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }}
      >
        <div className="d-flex align-items-center">
          <div className="me-3">
            <img
              // src={orgLogo ? orgLogo : image1}
              src={logoUrl}
              crossOrigin="anonymous"
              // src="http://localhost:3000/uploads/Piri_image.jpg"
              alt="Profile"
              className="rounded-circle"
              width="40"
              height="40"
              style={{ objectFit: "contain" }}
            />
          </div>
          <div className="flex-grow-1">
            <h5 className="mb-0">
            {orgName} 
            </h5>
            <h6>
              {roleName}
            </h6>
          </div>
          <div className="text-end me-3" style={{ display: "flex", gap: "10px" }}>
            <p className="mb-0">0 Clinics</p>
            <p className="mb-0">|</p>
            <p className="mb-0">0 Users</p>
          </div>
          <Button
            variant="dark"
            style={{ borderRadius: "5px", background: "#013E30" }}
          >
            Go To Dashboard
          </Button>
        </div>
      </div>

      <Row
        className="g-3"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "auto",
          minHeight: "69vh",
        }}
      >
        {cardData.map((card) => (
          <Col md={4} key={card.id}>
            <Card
              className="shadow-sm text-center"
              style={{ borderRadius: "10px", width: "90%", margin: "auto" }}
            >
              <Card.Body>
                <div className="mb-3">
                <img src={card.image} alt={`${card.title} icon`} />
                </div>
                <Card.Title
                  style={{
                    fontFamily: "'Uniform Rounded', sans-serif",
                    fontSize: "17px",
                    fontWeight: "500",
                   
                  }}
                >
                  {card.title}
                </Card.Title>
                <Card.Text
                  className="text-muted"
                  style={{
                    fontFamily: "'Uniform Rounded', sans-serif",
                    fontSize: "14px",
                    fontWeight: "500",
                    width: "80%",
                    margin: "auto",
                  }}
                >
                  {card.text}
                </Card.Text>
                <Button
                  style={{
                    fontFamily: "'Uniform Rounded', sans-serif",
                    fontSize: "14px",
                    fontWeight: "500",
                  }}
                  variant="link"
                  className="text-warning text-decoration-none"
                  href={card.buttonLink}
                >
                  {card.buttonText} &gt;
                </Button>
              </Card.Body>
              <div
                style={{
                  position: "absolute",
                  right: "0",
                  bottom: "10px",
                  width: "65px",
                  height: "64px",
                  backgroundColor: "#F0F0F0",
                  borderRadius: "51.5px 10px 0 0",
                  transform: "translateY(10px)",
                }}
              ></div>
            </Card>
          </Col>
        ))}
      </Row>
    </Fragment>
  );
};

export default Sales;