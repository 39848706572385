import React, { useState, ChangeEvent } from "react";
import Layout from "../layout";
import {
  StyledContainer,
  StyledHeading,
  StyledText,
  StyledColumn,
  StyledInput,
  StyledLabel,
  StyledButton,
  StyledLogo,
  StyledDiv,
} from "./styles";
import logo from "../../assets/images/layout/clinicLogo.png";
import { post } from "../../services/apiService";
// import { useSnackbar } from "../../services/snackbarContext";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
interface LocationState {
  email?: string;
}

interface ApiResponse<T> {
  status: string;
  message: string;
  data?: T;
}

const ConfirmPassword: React.FC = () => {
  const location = useLocation();
  const { email } = (location.state as LocationState) || {};
  // const { showSnackbar } = useSnackbar();
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const navigate = useNavigate();
  const handlePasswordChange = async (): Promise<void> => {
    if (password !== confirmPassword) {
      toast.error("Passwords do not match. Please try again.");
      return;
    }

    const newPassword = password;

    try {
      const response = await post<ApiResponse<null>>("/update-password", {
        email,
        newPassword,
      });
      if (response.status === "00") {
        toast.success("Password updated successfully");
        navigate("/signin");
      } else {
        toast.error("Not updated. Please try again.");
      }
    } catch (error: any) {
      toast.error(error.message || "Error occurred. Please try again.");
    }
  };
  const validatePassword = (password: string) => {
    const regex = /^(?=.*[A-Z])(?=.*[@$!%*?&.]).{8,}$/;
    if (!regex.test(password)) {
      setPasswordError(
        "Password must be at least 8 characters long, include an uppercase letter, and a special character."
      );
    } else {
      setPasswordError("");
    }
  };
  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement>,
    setState: React.Dispatch<React.SetStateAction<string>>
  ): void => {
    const value = event.target.value;
    setState(value);

    if (setState === setPassword) {
      validatePassword(value);
      if (confirmPassword && value !== confirmPassword) {
        setConfirmPasswordError("Passwords do not match.");

      } else {
        setConfirmPasswordError("");
      }
    }

    if (setState === setConfirmPassword) {
      if (value !== password) {
        setConfirmPasswordError("Passwords do not match.");
      } else {
        setConfirmPasswordError("");
      }
    }
  };

  return (
    <Layout>
      <ToastContainer position="top-right" autoClose={5000} />
      <StyledDiv>
        <StyledLogo src={logo} alt="Clinic Logo" />
      </StyledDiv>
      <StyledContainer>
        <StyledHeading>Forgot Your Password</StyledHeading>
        <StyledText>
          Please enter your new password and confirm password
        </StyledText>
        <StyledColumn>
          <StyledLabel>Password</StyledLabel>
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            <StyledInput
              type={showPassword ? "text" : "password"}
              placeholder="Enter password"
              value={password}
              onChange={(e) => handleInputChange(e, setPassword)}
              style={{ width: "100%", paddingRight: "40px" }}
            />
            <div
              onClick={() => setShowPassword(!showPassword)}
              style={{
                position: "absolute",
                right: "10px",
                cursor: "pointer",
              }}
            >
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </div>
          </div>
          {passwordError && (
            <div style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
              {passwordError}
            </div>
          )}
        </StyledColumn>
        <StyledColumn>
          <StyledLabel>Confirm Password</StyledLabel>
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
            }}
          >
            <StyledInput
              type={showConfirmPassword ? "text" : "password"}
              placeholder="Confirm password"
              value={confirmPassword}
              onChange={(e) => handleInputChange(e, setConfirmPassword)}
              style={{ width: "100%", paddingRight: "40px" }}
            />
            <div
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              style={{
                position: "absolute",
                right: "10px",
                cursor: "pointer",
              }}
            >
              {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
            </div>
          </div>
          {confirmPasswordError && (
            <div style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
              {confirmPasswordError}
            </div>
          )}
        </StyledColumn>
        <StyledButton onClick={handlePasswordChange}>
          Reset Password
        </StyledButton>
        <StyledText isAlternate={true}>
          Need help?<span>Contact Us</span>
        </StyledText>
      </StyledContainer>
  
    </Layout>
  );
};

export default ConfirmPassword;
