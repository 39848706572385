import { post } from '../services/apiService';
import { PayPalOrderResponse } from '../types/payment.types';

export class PayPalService {
  private static readonly ENDPOINTS = {
    CREATE_ORDER: '/create-order',
    CAPTURE_ORDER: '/capture-order',
  };

  static async createOrder(amount: string, currency: string = 'USD'): Promise<string> {
    try {
      const response = await post<PayPalOrderResponse>(
        this.ENDPOINTS.CREATE_ORDER,
        { amount, currency }
      );
      
      if (response.error) throw new Error(response.error);
      if (!response.orderId) throw new Error('No order ID received');
      
      return response.orderId;
    } catch (error) {
      throw this.handleError(error);
    }
  }

  static async captureOrder(orderId: string): Promise<PayPalOrderResponse> {
    try {
      const response = await post<PayPalOrderResponse>(
        this.ENDPOINTS.CAPTURE_ORDER,
        { orderId }
      );
      
      if (response.error) throw new Error(response.error);
      if (!response.details) throw new Error('No order details received');
      
      return response;
    } catch (error) {
      throw this.handleError(error);
    }
  }

  private static handleError(error: unknown): Error {
    if (error instanceof Error) return error;
    return new Error('An unknown error occurred during payment processing');
  }
}