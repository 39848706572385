import { Fragment, useEffect, useState } from "react";
import { Provider } from "react-redux";
import store from "../redux/store";
import Header from "../components/common/header/header";
import Switcher from "../components/common/switcher/switcher";
import Sidebar from "../components/common/sidebar/sidebar";
import Pageheader from "../components/pageheader/pageheader";
import { Outlet } from "react-router-dom";
import Tabtotop from "../components/common/tab-to-tap/tabtotap";

function App() {
  const [lateLoad, setlateLoad] = useState(false);
  useEffect(() => {
    setlateLoad(true);
  });

  return (
    <Fragment>
      <Provider store={store}>
        <div style={{ display: `${lateLoad ? "block" : "none"}` }}>
          <Switcher />
          <div className="page">
            <Header />
            <Sidebar />
            <Pageheader />
            <div className="main-content app-content">
              <div className="container-fluid">
                <Outlet />
              </div>
            </div>
          </div>
          <Tabtotop />
        </div>
      </Provider>
    </Fragment>
  );
}

export default App;
