import React from "react";
import "./index.scss";
import ReactDOM from "react-dom/client";
import App from "./pages/App.tsx";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Analytics from "./container/dashboards/analytics/analytics.tsx";
import Courses from "./container/dashboards/courses/courses.tsx";
import Crm from "./container/dashboards/crm/crm.tsx";
import Crypto from "./container/dashboards/crypto/crypto.tsx";
import Ecommerce from "./container/dashboards/ecommerce/ecommerce.tsx";
import Hrm from "./container/dashboards/hrm/hrm.tsx";
import Jobs from "./container/dashboards/jobs/jobs.tsx";
import Nft from "./container/dashboards/nft/nft.tsx";
import Personal from "./container/dashboards/personal/personal.tsx";
import Projects from "./container/dashboards/projects/projects.tsx";
import Sales from "./container/dashboards/sales/sales.tsx";
import Stocks from "./container/dashboards/stocks/stocks.tsx";
import Auth from "./firebase/auth.tsx";
import Login from "./firebase/login.tsx";
import Signup from "./firebase/signup.tsx";
import Authenticationlayout from "./pages/authenticationlayout.tsx";
import Landinglayout from "./pages/landinglayout.tsx";
import Pagelayout from "./pages/pagelayout.tsx";
import ScrollToTop from "./components/common/scrolltotop/scrolltotop.tsx";
import Subscription from "./components/client/subscription/subscription.tsx";
import Signin from "./pages/Signin/Signin.tsx";
import ConfirmPassword from "./pages/ConfirmPass/ConfirmPass.tsx";
import ForgotPassword from "./pages/FogotPass/FogotPass.tsx";
import ResetRequest from "./pages/ResetRequest/ResetRequest.tsx";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.Fragment>
    <BrowserRouter>
      <ScrollToTop />
      <React.Suspense>
        <Routes>
          <Route path={`${import.meta.env.BASE_URL}`} element={<Auth />}>
            {/* <Route index element={<Login />} /> */}
            <Route index element={<Subscription />} />
            <Route
              path={`${import.meta.env.BASE_URL}firebase/login`}
              element={<Login />}
            />
            <Route
              path={`${import.meta.env.BASE_URL}firebase/signup`}
              element={<Signup />}
            />
          </Route>
          <Route path={`${import.meta.env.BASE_URL}`} element={<App />}>
            <Route
              path={`${import.meta.env.BASE_URL}dashboards/crm`}
              element={<Crm />}
            />
            <Route
              path={`${import.meta.env.BASE_URL}dashboards/ecommerce`}
              element={<Ecommerce />}
            />
            <Route
              path={`${import.meta.env.BASE_URL}dashboards/crypto`}
              element={<Crypto />}
            />
            <Route
              path={`${import.meta.env.BASE_URL}dashboards/jobs`}
              element={<Jobs />}
            />
            <Route
              path={`${import.meta.env.BASE_URL}dashboards/nft`}
              element={<Nft />}
            />
            {/* <Route
              path={`${import.meta.env.BASE_URL}dashboards/UserSetup`}
              element={<Sales />}
            /> */}
            <Route
              path="dashboards/UserSetup"
              element={<Sales />}
            />
            <Route
              path={`${import.meta.env.BASE_URL}dashboards/analytics`}
              element={<Analytics />}
            />
            <Route
              path={`${import.meta.env.BASE_URL}dashboards/projects`}
              element={<Projects />}
            />
            <Route
              path={`${import.meta.env.BASE_URL}dashboards/hrm`}
              element={<Hrm />}
            />
            <Route
              path={`${import.meta.env.BASE_URL}dashboards/stocks`}
              element={<Stocks />}
            />
            <Route
              path={`${import.meta.env.BASE_URL}dashboards/courses`}
              element={<Courses />}
            />
            <Route
              path={`${import.meta.env.BASE_URL}dashboards/personal`}
              element={<Personal />}
            />
          </Route>
          <Route
            path={`${import.meta.env.BASE_URL}`}
            element={<Authenticationlayout />}
          ></Route>
          <Route
            path={`${import.meta.env.BASE_URL}`}
            element={<Landinglayout />}
          ></Route>
          <Route
            path={`${import.meta.env.BASE_URL}`}
            element={<Pagelayout />}
          ></Route>
        
           {/* <Route
            path={`${import.meta.env.BASE_URL}subscribe`}
            element={<SubscriptionForm />}
            ></Route> */}
            {/* <Route
            path={`${import.meta.env.BASE_URL}subscribe`}
            element={<Subscription />}
          ></Route> */}
           <Route
            path="subscribe"
            element={<Subscription />}
          ></Route>
             <Route
            path="signin"
            element={<Signin />}
          ></Route>
             <Route
            path="confirm"
            element={<ConfirmPassword />}
          ></Route>
           <Route
            path="forgot"
            element={<ForgotPassword />}
          ></Route>
          <Route
            path="reset"
            element={<ResetRequest />}
          ></Route>
          
        </Routes>
      </React.Suspense>
    </BrowserRouter>
  </React.Fragment>
);
