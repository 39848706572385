import styled from "styled-components";
// import { ArrowIosBackOutline } from "@styled-icons/evaicons-outline/ArrowIosBackOutline";

// Define props interfaces for styled components
interface StyledLineProps {
  isSmall?: boolean;
}

interface StyledLabelProps {
  isSmall?: boolean;
}

interface StyledButtonProps {
  isAlternate?: boolean;
}

interface StyledVisaDivProps {
  isAlternate?: boolean;
}

// Styled Components
export const StyledContainer = styled.div`
  width: 100%;
  margin: 4% 0 0 0;
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
`;

export const StyledLeftDiv = styled.div`
  border: 1px solid #ededed;
  width: 60%;
  border-radius: 10px;
  box-shadow: 5px 5px 25px 5px #0000000d;
  background: #fff;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 15px;
  @media (max-width: 1080px) {
    width: 80%;
  }
  @media (max-width: 640px) {
    width: 100%;
  }
`;

export const StyledRightDiv = styled.div`
  background: #fff;
  border: 1px solid #ededed;
  border-radius: 10px;
  width: 40%;
  box-shadow: 5px 5px 25px 5px #0000000d;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const StyledHeading = styled.p`
  font-weight: 500;
  font-size: 20px;
  color: #000;
  line-height: 23.5%;
  @media (max-width: 800px) {
    line-height: normal;
    text-align: center;
    font-size: 18px;
  }
`;

export const StyledLine = styled.p<StyledLineProps>`
  color: #909090;
  font-size: ${(props) => (props.isSmall ? "12px" : "14px")};
  font-weight: 400;
  @media (max-width: 800px) {
    line-height: normal;
    text-align: center;
  }
`;

export const StyledPaymentOption = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const StyledRadioButton = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const StyledLabel = styled.label<StyledLabelProps>`
  font-size: ${(props) => (props.isSmall ? "12px" : "14px")};
  font-weight: 500;
  cursor: pointer;
  color: #034737;
  padding-left: ${(props) => (props.isSmall ? "1%" : "0")};
`;

export const StyledInput = styled.input`
  appearance: none;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  outline: 1px double #ff9800;
  outline-offset: 3px;
  border: 1px solid #fff;
  cursor: pointer;

  &:checked {
    background-color: #ff9800;
    border: 1px solid #ff9800;
  }
`;

export const StyledNestedDiv = styled.div``;

export const StyledCardDetails = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;

  ${StyledNestedDiv} {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
  }
`;

export const StyledButton = styled.button<StyledButtonProps>`
  background-color: #013e30;
  color: white;
  border: none;
  border-radius: 4px;
  padding: ${(props) => (props.isAlternate ? "5px 20px" : "10px 20px")};
  font-size: ${(props) => (props.isAlternate ? "0.8rem" : "1rem")};
  cursor: pointer;
  margin-top: ${(props) => (props.isAlternate ? "0" : "10px")};

  &:hover {
    background-color: #013e30;
  }
`;

export const StyledFooterText = styled.p`
  font-size: 0.8rem;
  color: #666;
  margin-top: 15px;

  a {
    color: #008000;
    text-decoration: none;
    font-weight: 500;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const StyledDiv = styled.div`
  border: 1px solid #ededed;
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledVisaDiv = styled.div<StyledVisaDivProps>`
  border: 1px solid #ededed;
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: ${(props) => (props.isAlternate ? "20px" : "10px")};
  background: #fafafa;
`;

export const StyledVisaRadioDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledDivider = styled.div`
  background: #ededed;
  height: 1px;
  width: 100%;
`;

export const StyledIconsDiv = styled.div`
  display: flex;
  gap: 10px;
`;

export const StyledIcon = styled.img``;

export const StyledVisaInput = styled.input`
  flex: 1;
  width: 100%;
  border: 1px solid #ededed;
  background: #ffffff;
  padding: 10px;
  border-radius: 5px;

  &::placeholder {
    color: #c0c0c0;
    font-size: 12px;
  }

  &:focus {
    outline: none;
    border: 1px solid #ff9800;
  }
`;

export const StyledRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const StyledCheckboxWrapper = styled.div`
  display: flex;
  align-items: flex-start;

  input[type="checkbox"] {
    margin-right: 0.5rem;
    width: 1rem;
    height: 1rem;
    accent-color: #ff9800;
    cursor: pointer;

    &:checked {
      color: white;
    }
  }

  label {
    font-size: 12px;
    color: #555;
    line-height: 1.4;
    cursor: pointer;

    a {
      color: #ff9800;
      text-decoration: none;
      font-weight: 600;

      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

export const StyledBorderedDiv = styled.div`
  border: 1px solid #ff9800;
  background: #fff;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 10px;

  p {
    color: #949494;
    font-size: 14px;
    font-weight: 500;
  }
`;

export const StyledAmountRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledAmountText = styled.p`
  color: #034737;
  font-size: 14px;
  font-weight: 500;

  span {
    color: #909090;
    font-size: 12px;
    float: right;
  }
`;

export const StyledAmountTag = styled.p`
  color: #909090;
  font-size: 14px;
  font-weight: 500;
`;

export const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const StyledBackButton = styled.button`
  border: none;
  background: transparent;
  color: #ff9800;
  font-weight: 600;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

// export const BackIcon = styled(ArrowIosBackOutline)`
//   cursor: pointer;
//   fill: #ff9800;
//   height: 20px;
// `;
export const BackIcon = styled.div`
  cursor: pointer;
  fill: #ff9800;
  height: 20px;
`;
