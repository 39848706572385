import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Layout from "../layout";
import {
  StyledContainer,
  StyledHeading,
  StyledText,
  StyledButton,
  StyledDivider,
  StyledGreenBar,
  StyledDiv,
  StyledLogo,
} from "./styles";
import logo from "../../assets/images/layout/clinicLogo.png";
// import { useSnackbar } from "../../services/snackbarContext";


const ResetRequest: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
    // const { showSnackbar } = useSnackbar();

    const searchParams = new URLSearchParams(location.search);
    const email = searchParams.get("email");
    console.log("email", email);

  return (
    <Layout>
      <StyledContainer>
        <StyledDiv>
          <StyledLogo src={logo} alt="Clinic Logo" />
        </StyledDiv>
        <StyledHeading>Password Change Request</StyledHeading>
        <StyledDivider />
        <StyledText isAlternate={true}>
          If you’ve lost your password or wish to reset it, use the link below
          to get started.
        </StyledText>
        <StyledButton
         disabled={!email}
          onClick={() => navigate("/confirm", { state: { email } })}
        >
          Reset your Password
        </StyledButton>
        <StyledText>
          If you did not request a password reset, you can safely ignore this
          email. Only a person with access to your email can reset your account
          password.
        </StyledText>
        <StyledGreenBar>www.qliniq.org</StyledGreenBar>
      </StyledContainer>
    </Layout>
  );
};

export default ResetRequest;
